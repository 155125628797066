import { UserAgentUtils } from 'esp-globals';

import { formValueSelector, getFormMeta } from 'redux-form/immutable';
// Utils
import browserHistory from '../../utils/browserHistory';
import buildCompositeController from '../../utils/buildCompositeController';
// Actions
import sessionThunks from '../../actions/sessionThunks';
import tenantThunks from '../../actions/tenantThunks';
// Utils
import SSOUtils from '../../utils/SSOUtils';
import uiPathGenerator from '../../utils/uiPathGenerator';
import {
  app as appTeams,
  authentication as teamsAuthentication,
} from '@microsoft/teams-js';
import queryString from 'query-string';
import {
  CLIENT_CHAT_V2,
  CLIENT_TEAMS_FRAME,
  CLIENT_WIDGET_FRAME,
} from '../../globals/clientFrameKeys';

// Selectors
// import getActiveFields          from '../../selectors/getActiveFields';

const FORM = 'LoginForm';

const getLoginHint = () => queryString.parse(window.location.search).login_hint;

const mapStateToProps = (state) => {
  const redirectTo =
    state.getIn(['session', 'previousUrl']) ||
    uiPathGenerator.genLandingPath(state);

  // if the form has been focused, then this will be true
  const hasFormBeenFocused = Boolean(getFormMeta(FORM)(state));

  let tenantImageHeight;

  // since android devices won't auto scroll the view
  // we need to shrink the tenant logo
  // once the form has been focused
  if (
    hasFormBeenFocused &&
    (UserAgentUtils.isAndroidWeb() || UserAgentUtils.isAndroidApp())
  ) {
    // height for nexus 6
    if (UserAgentUtils.isNexus6()) {
      tenantImageHeight = '150px';
    } else {
      // height for other android devices
      tenantImageHeight = '50px';
    }
  }

  return {
    email: formValueSelector(FORM)(state, 'email') || '',
    error: state.getIn(['session', 'error']),
    externalLoginChecked: state.getIn(['session', 'externalLoginChecked']),
    externalLoginUri: state.getIn(['session', 'externalLoginRedirectUri']),
    isLoading: state.getIn(['session', 'isLoading']),
    previousURL: redirectTo,
    tenantImageHeight: tenantImageHeight,
  };
};

const mapDispatchToProps = (dispatch) => ({
  formValidation: (values) => {
    const errors = {};
    const email = values.get('email') || '';
    const password = values.get('password') || '';
    const emptyFieldError = 'Please add a value';
    // const invalidEmail = 'Please add a valid email';

    if (!email) {
      errors.email = emptyFieldError;
    }
    if (!password) {
      errors.password = emptyFieldError;
    }

    return errors;
  },
  getAuthLogin: () => {
    dispatch(sessionThunks.getAuthLogin());
  },
  handleSubmit: (data, previousURL) => {
    dispatch(sessionThunks.userLogin(data, true)) // force keep me logged
      .then(() => {
        if (localStorage.getItem(CLIENT_CHAT_V2)) {
          console.log('Redirecting to chat v2 app'); // eslint-disable-line no-console -- debugging

          localStorage.removeItem(CLIENT_CHAT_V2);
          const chatAppPath = uiPathGenerator.genPath('chatV2App');

          window.location.replace(`${chatAppPath}/`);
          return;
        }

        if (localStorage.getItem(CLIENT_TEAMS_FRAME)) {
          console.log('Redirecting to teams app'); // eslint-disable-line no-console -- debugging

          localStorage.removeItem(CLIENT_TEAMS_FRAME);

          try {
            // If the home feed launched the auth flow in popup notify success, so it can close it
            appTeams.initialize().then(() => {
              teamsAuthentication.notifySuccess(
                'Team authentication successful'
              );
            });
          } catch (e) {
            // eslint-disable-next-line no-console -- debugging
            console.warn('Could not notify teams sdk auth success', e.message);
          }
          const teamsPath = uiPathGenerator.genPath('teamsApp');
          window.location.replace(teamsPath);
          return;
        }

        if (localStorage.getItem(CLIENT_WIDGET_FRAME)) {
          localStorage.removeItem(CLIENT_WIDGET_FRAME);

          browserHistory.replace(uiPathGenerator.genPath('widgetAuthSuccess'));
          return;
        }

        browserHistory.push(previousURL);

        dispatch(tenantThunks.loadTenantLabelConfiguration()); // Load tenant menu config
        dispatch(tenantThunks.getAllowedExtensions()); // Load Tenant allowed extension
      })
      .catch(() => {
        // We're not doing anything here since this thunk throws an action creator
        // sessionActions.userLoginFailure that will record the error
        // throw new Error(error);
      });
  },

  loginExternally: (externalLoginUri) => {
    const hint = getLoginHint();

    if (hint) {
      externalLoginUri = `${externalLoginUri}&login_hint=${encodeURIComponent(
        hint
      )}`;
    }
    SSOUtils.loginExternally(externalLoginUri);
  },
});

export default buildCompositeController(mapStateToProps, mapDispatchToProps);
