const endpointPaths = {
  children: {
    authentication: {
      children: {
        activeSessions: {
          pattern: 'active_sessions',
        },

        auth: {
          children: {
            login: {
              children: {
                azureGetToken: {
                  children: {},
                  pattern: 'azure_get_token',
                },
                exchangeOtc: {
                  children: {},
                  pattern: 'exchange_otc',
                },
                requestOtc: {
                  children: {},
                  pattern: 'request_otc',
                },
              },
              pattern: 'login',
            },

            logout: {
              children: {},
              pattern: 'logout',
            },

            logoutAllAsAdmin: {
              children: {},
              pattern: 'logoutallasadmin',
            },
          },
          pattern: 'auth',
        },

        authBackend: {
          children: {
            instance: {
              children: {},
              pattern: ':azureID',
            },
          },
          pattern: 'auth_backend',
        },

        authSAML: {
          children: {
            response: {
              children: {},
              pattern: 'response',
            },
          },
          pattern: 'saml',
        },

        oktaTenant: {
          children: {
            instance: {
              children: {},
              pattern: ':oktaID',
            },
          },

          pattern: 'okta_tenant',
        },

        samlIdp: {
          children: {},
          pattern: 'saml_idp',
        },

        samlSP: {
          children: {
            instance: {
              children: {},
              pattern: ':id',
            },
          },
          pattern: 'saml_sp',
        },
      },

      pattern: 'authentication/v0.1',
    },

    commons: {
      children: {
        apiTracking: {
          children: {},
          pattern: 'api_tracking',
        },

        jobStatus: {
          children: {
            instance: {
              children: {
                cancel: {
                  children: {},
                  pattern: 'cancel',
                },
              },
              pattern: ':statusID',
            },
          },
          pattern: 'job_status',
        },

        localization: {
          children: {
            instance: {
              children: {},
              pattern: ':localizationID',
            },
          },
          pattern: 'localization',
        },

        object_mapping: {
          pattern: 'object_mapping',
        },

        supportedLanguages: {
          children: {},
          pattern: 'supported_languages',
        },
        validateUrlRedirect: {
          children: {},
          pattern: 'validate_url_redirect',
        },
        version: {
          children: {},
          pattern: 'version',
        },
      },
      pattern: 'common/v0.1',
    },

    emailSensor: {
      children: {
        caseGenerators: {
          children: {
            instance: {
              children: {},
              pattern: ':caseGeneratorID',
            },
          },
          pattern: 'case_generators',
        },
      },
      pattern: 'email_sensor/v0.1',
    },

    emails: {
      children: {
        credentials: {
          children: {
            emailID: {
              children: {},
              pattern: ':emailID',
            },
            instance: {
              children: {
                oauth_connect: {
                  children: {},
                  pattern: 'oauth_connect',
                },
                oauth_disconnect: {
                  children: {},
                  pattern: 'oauth_disconnect',
                },
                test_connection: {
                  children: {},
                  pattern: 'test_connection',
                },
              },
              pattern: ':emailID',
            },
          },
          pattern: 'credentials',
        },
      },
      pattern: 'emails/v0.1',
    },

    espAssets: {
      children: {
        instance: {
          children: {},
          pattern: ':assetID',
        },
      },

      pattern: 'assets/v0.1/asset',
    },

    espBEVersion: {
      pattern: 'version',
    },

    espBarista: {
      children: {
        application: {
          children: {
            instance: {
              children: {
                intents: {
                  children: {
                    instance: {
                      children: {
                        parameters: {
                          children: {},
                          pattern: 'parameters',
                        },
                      },
                      pattern: ':intentID',
                    },
                  },
                  pattern: 'intents',
                },
              },
              pattern: ':applicationID',
            },
          },
          pattern: 'application',
        },
        archetypes: {
          children: {
            filter_by_entity_value: {
              children: {},
              pattern: 'filter_by_entity_value',
            },
            listForFaq: {
              children: {},
              pattern: 'list_for_faq',
            },

            template: {
              children: {
                primaryEntity: {
                  children: {},
                  pattern: 'primary_entity',
                },
              },
              pattern: ':templateEid',
            },
          },
          pattern: 'archetypes',
        },
        conversation: {
          children: {
            instance: {
              children: {
                changeState: {
                  children: {},
                  pattern: 'change_state',
                },
                setExitMethod: {
                  children: {},
                  pattern: 'set_exit_method',
                },
              },
              pattern: ':conversationID',
            },

            startConversationWithParameters: {
              children: {},
              pattern: 'start_conversation_with_parameters',
            },
          },
          pattern: 'conversation',
        },
        elc: {
          children: {
            importAllElc: {
              children: {},
              pattern: 'import_all_elc',
            },
          },
          pattern: 'elc',
        },
        entities: {
          children: {
            instance: {
              children: {
                values: {
                  children: {
                    instance: {
                      children: {
                        instances: {
                          children: {
                            value: {
                              pattern: ':synonymEid',
                            },
                          },
                          pattern: 'instances',
                        },
                      },
                      pattern: ':valueEID',
                    },
                  },
                  pattern: 'values',
                },
              },
              pattern: ':entityEID',
            },
            search: {
              children: {},
              pattern: 'search',
            },
          },
          pattern: 'entities',
        },
        faqKbs: {
          children: {
            faqResponseLookup: {
              children: {},
              pattern: 'faq_responses_lookup',
            },
          },
          pattern: 'faq_kbs',
        },

        faqs: {
          children: {
            general: {
              children: {
                faqs_and_templates_lookup: {
                  children: {},
                  pattern: 'faqs_and_templates_lookup',
                },
                filterByArchetypeAndEntity: {
                  children: {},
                  pattern: 'filter_by_archetype_and_entity',
                },
                filterByEntityValue: {
                  children: {},
                  pattern: 'filter_by_entity_value',
                },
                instance: {
                  children: {
                    answers: {
                      children: {
                        instance: {
                          children: {
                            lines: {
                              children: {
                                instance: {
                                  children: {},
                                  pattern: ':lineID',
                                },
                              },
                              pattern: 'lines',
                            },
                          },
                          pattern: ':answerID',
                        },
                      },
                      pattern: 'answers',
                    },
                    get_archetype_condition_elements: {
                      children: {},
                      pattern: 'get_archetype_condition_elements',
                    },
                    kbArticle: {
                      // /api/barista/v0.1/faqs/general/<faq_eid>/additional_text
                      children: {},
                      pattern: 'additional_text',
                    },
                    primaryEntity: {
                      children: {},
                      pattern: 'primary_entity',
                    },
                  },
                  pattern: ':faqID',
                },
                intentLookup: {
                  children: {},
                  pattern: 'intent_lookup',
                },
              },
              pattern: 'general',
            },
            instance: {
              children: {
                questions: {
                  children: {
                    instance: {
                      children: {
                        answers: {
                          children: {
                            instance: {
                              children: {
                                lines: {
                                  children: {},
                                  pattern: 'lines',
                                },
                              },
                              pattern: ':answerID',
                            },
                          },
                          pattern: 'answers',
                        },
                        variations: {
                          children: {},
                          pattern: 'variations',
                        },
                      },
                      pattern: ':questionID',
                    },
                  },
                  pattern: 'questions',
                },
              },
              pattern: ':faqID',
            },
          },
          pattern: 'faqs',
        },
        getSupportChannel: {
          children: {},
          pattern: 'conversation/get_support_channel',
        },
        intents: {
          children: {
            annotationDetail: {
              children: {},
              pattern: 'annotation_detail',
            },
            instance: {
              children: {
                annotation: {
                  children: {
                    instance: {
                      children: {},
                      pattern: ':kbEid',
                    },
                  },
                  pattern: 'annotation',
                },
                bulkAnnotate: {
                  children: {
                    instance: {
                      children: {},
                      pattern: ':kbEid',
                    },
                  },
                  pattern: 'bulk_annotate',
                },
                examplePhrases: {
                  children: {},
                  pattern: 'example_phrases',
                },
                responses: {
                  children: {
                    instance: {
                      children: {
                        phrases: {
                          children: {
                            instance: {
                              children: {},
                              pattern: ':phraseEID',
                            },
                          },
                          pattern: 'phrases',
                        },
                      },
                      pattern: ':responseID',
                    },
                  },
                  pattern: 'responses',
                },
              },
              pattern: ':intentID',
            },
          },
          pattern: 'intents',
        },
        kbArticles: {
          children: {
            counters: {
              pattern: 'counters',
            },
            getAddedUpdatedOvertime: {
              pattern: 'get_kbs_added_updated_overtime',
            },
            getByLanguage: {
              pattern: 'get_kbs_by_language',
            },
            getBySource: {
              pattern: 'get_kbs_by_source',
            },
            getLeast20ConversationAreas: {
              pattern: 'get_least_20_conversation_areas',
            },
            getSuggestedOvertime: {
              pattern: 'get_kbs_suggested_overtime',
            },
            getTop20ConversationAreas: {
              pattern: 'get_top_20_conversation_areas',
            },
          },
          pattern: 'kb_articles',
        },
        kbSupport: {
          children: {
            annotation: {
              children: {
                intent: {
                  pattern: ':intentEID',
                },
              },
              pattern: ':kbArticleID/annotation',
            },
            bulkAnnotation: {
              children: {},
              pattern: ':kbArticleID/bulk_annotate',
            },
            getKBViews: {
              children: {},
              pattern: 'get_kb_views',
            },
            knowledgeArticle: {
              children: {
                runImportOne: {
                  children: {},
                  pattern: 'run_import_one',
                },
              },
              pattern: ':id',
            },
            listCompletedKb: {
              children: {},
              pattern: 'list_completed_kb',
            },
            origins: {
              children: {},
              pattern: 'origins',
            },
          },
          pattern: 'kb_support',
        },
        report: {
          children: {
            automationByStatus: {
              pattern: 'get_automation_reports_by_status',
            },
            automationStats: {
              pattern: 'automation_stats',
            },

            contentFeedback: {
              pattern: 'get_content_feedback',
            },
            counters: {
              pattern: 'counters',
            },
            deflectedData: {
              pattern: 'get_deflected_data',
            },
            geoDistribution: {
              pattern: 'get_geo_distribution',
            },
            getLiveChatFeedbackKeys: {
              pattern: 'get_live_chat_feedback_keys',
            },
            getTopIntentsDrivingAutomation: {
              pattern: 'get_top_intents_driving_automation',
            },
            getTopUnansweredQuestionsByKeywordsGroup: {
              pattern: 'get_top_unanswered_questions_by_keywords_group',
            },

            instance: {
              children: {
                redactConversation: {
                  children: {},
                  pattern: 'redact_conversation',
                },
              },
              pattern: ':interactionEID',
            },
            interactionsByChannels: {
              pattern: 'get_interactions_by_channel',
            },
            interactionsByMatchedArchetype: {
              pattern: 'get_interactions_by_matched_archetype',
            },

            interactionsByOsClient: {
              pattern: 'get_interactions_by_os_client',
            },
            interactionsBySource: {
              pattern: 'get_interactions_by_source',
            },

            topInteractionsByTopic: {
              pattern: 'get_interactions_by_topic',
            },
            topMatchedIntents: {
              pattern: 'get_top_matched_intents',
            },
            topMatchedIntentsDrivingDeflections: {
              // get_top_matched_intents_driving_deflection
              pattern: 'get_top_matched_intents_driving_deflection',
            },
            topUnansweredQuestionsWithAndWithoutKb: {
              pattern: 'get_top_unanswered_questions_with_and_without_kb',
            },
            unansweredQuestions: {
              pattern: 'get_top_unanswered_questions',
            },
          },
          pattern: 'report9_data',
        },
        report9Data: {
          children: {
            csv: {
              pattern: 'csv',
            },
          },
          pattern: 'report9_data',
        },
        reports: {
          children: {
            getReport11: {
              children: {},
              pattern: 'get_report11',
            },
            getReport9: {
              children: {},
              pattern: 'get_report9',
            },
            report11: {
              children: {},
              pattern: 'report11',
            },
            report9: {
              children: {},
              pattern: 'report9_v2',
            },
          },
          pattern: 'reports',
        },
        scheduledElcSync: {
          children: {
            instance: {
              pattern: ':EID',
            },
          },
          pattern: 'scheduled_elc_sync',
        },
      },

      pattern: 'barista/v0.1',
    },

    espBaristaBot: {
      children: {
        add: {
          children: {},
          pattern: 'add',
        },
        deeplink: {
          children: {},
          pattern: 'deeplink',
        },
        genToken: {
          children: {},
          pattern: 'genToken',
        },
        integrations: {
          children: {
            instance: {
              children: {},
              pattern: ':botType',
            },
            slackChannels: {
              children: {},
              pattern: 'slack_channels',
            },
          },
          pattern: 'integrations',
        },

        remove: {
          children: {},
          pattern: 'remove',
        },
        teams: {
          children: {
            add: {
              children: {},
              pattern: 'add',
            },
            addTeamsSdk: {
              children: {},
              pattern: 'add_teams_sdk',
            },
            azureGroups: {
              children: {},
              pattern: 'azure_groups',
            },
            checkStatus: {
              children: {},
              pattern: 'check_teams_status',
            },
            remove: {
              children: {},
              pattern: 'remove',
            },
            removeTeamsSdk: {
              children: {},
              pattern: 'remove_teams_sdk',
            },
            sendWelcomeMessage: {
              children: {},
              pattern: 'send_welcome_message',
            },
          },
          pattern: 't',
        },
      },
      pattern: 'baristabot/v0.1',
    },

    espBootstrap: {
      children: {
        start: {
          children: {},
          pattern: 'start',
        },
      },

      pattern: 'bootstrap/v0.1',
    },

    espCSV: {
      children: {
        csvfile: {
          children: {
            csvId: {
              children: {
                changeParseStatus: {
                  children: {},
                  pattern: 'change_parse_status',
                },
              },
              pattern: ':csvId',
            },
            instance: {
              children: {
                parseCSV: {
                  children: {},
                  pattern: 'parse_csv',
                },
              },
              pattern: ':invitesImportId',
            },
          },
          pattern: 'csvfile',
        },
        remote: {
          children: {
            instance: {
              children: {
                testConnection: {
                  pattern: 'test_connection',
                },
              },
              pattern: ':scheduleID',
            },
            test_connection: {
              pattern: 'test_connection',
            },
          },
          pattern: 'remote_server_info',
        },
      },

      pattern: 'csv/v0.1',
    },

    espCards: {
      children: {
        cancelCard: {
          children: {},
          pattern: 'card/:cardID/cancel',
        },

        cards: {
          children: {
            instance: {
              children: {},

              pattern: ':cardID',
            },
          },

          pattern: 'card',
        },

        confirmCard: {
          children: {},
          pattern: 'card/:cardID/confirm',
        },
        dismissCard: {
          children: {},
          pattern: 'card/:cardID/dismiss',
        },

        followCard: {
          children: {},
          pattern: 'card/:cardID/follow',
        },

        nudgeCard: {
          children: {},
          pattern: 'card/:cardID/nudge',
        },

        snoozeCard: {
          children: {},
          pattern: 'card/:cardID/snooze',
        },
        submitFeedback: {
          children: {},
          pattern: 'card/:cardID/submit_feedback',
        },
      },

      pattern: 'cards/v0.1',
    },

    espCaseMgmt: {
      children: {
        conditions: {
          children: {
            instance: {
              children: {},
              pattern: ':conditionID',
            },
            swap: {
              pattern: 'swap',
            },
          },
          pattern: 'conditions',
        },
        espServiceDepartment: {
          children: {},
          pattern: 'esp_service_department',
        },
        locationServiceTeam: {
          children: {
            instance: {
              children: {},
              pattern: ':locationTeamID',
            },
          },
          pattern: 'location_service_team',
        },
        locationUser: {
          children: {
            instance: {
              children: {},
              pattern: ':locationID',
            },
          },
          pattern: 'location_user',
        },

        serviceDepartment: {
          children: {
            instance: {
              children: {
                addUserToGroup: {
                  children: {},
                  pattern: 'add_user_to_group',
                },

                members: {
                  children: {},
                  pattern: 'members',
                },

                removeUserFromGroup: {
                  children: {},
                  pattern: 'remove_user_from_group',
                },
              },
              pattern: ':serviceDepartmentID',
            },
          },

          pattern: 'service_department',
        },
        serviceDepartmentClassification: {
          children: {},
          pattern: 'esp_service_department',
        },
        serviceDepartmentRoute: {
          children: {
            instance: {
              children: {},
              pattern: ':serviceDepartmentRouteID',
            },
          },
          pattern: 'service_department_route',
        },
        serviceTeam: {
          children: {
            areFeedsRead: {
              childrne: {},
              pattern: 'are_feeds_read',
            },
            defaultTeam: {
              childrne: {},
              pattern: 'default_team',
            },
            instance: {
              children: {
                changeDirectLinkedTeamLead: {
                  children: {},
                  pattern: 'change_direct_linked_team_lead',
                },
                changeServiceDepartment: {
                  children: {},
                  pattern: 'change_service_department',
                },
                createMembership: {
                  children: {},
                  pattern: 'create_membership',
                },
                importMembersFromServiceNow: {
                  children: {},
                  pattern: 'import_members_from_servicenow',
                },
                removeMembership: {
                  children: {},
                  pattern: 'remove_membership',
                },
                sendInvitation: {
                  children: {},
                  pattern: 'send_invitation',
                },
                setFeedRead: {
                  children: {},
                  pattern: 'set_feed_read',
                },
              },
              pattern: ':serviceTeamID',
            },
            myTeams: {
              childrne: {},
              pattern: 'my_teams',
            },
          },

          pattern: 'service_team',
        },

        serviceTeamClassification: {
          children: {},
          pattern: 'service_team_classification',
        },
        // serviceTeamRelationship: {
        //   pattern: 'service_team_relationship',
        //
        //   children: {
        //     instance: {
        //       pattern  : ':relationshipID',
        //       children : {
        //         sendInvitation: {
        //           pattern  : 'send_invitation',
        //           children : {},
        //         },
        //       },
        //     },
        //   },
        // },
        serviceTeamImage: {
          children: {},
          pattern: 'service_team_image',
        },
        serviceTeamRole: {
          children: {},
          pattern: 'service_team_role',
        },
        summary: {
          children: {},
          pattern: 'summary',
        },
      },
      pattern: 'case_mgmt/v0.1',
    },

    espCatalog: {
      children: {
        bundleImage: {
          children: {
            instance: {
              children: {},
              pattern: ':imageID',
            },
          },

          pattern: 'bimage',
        },

        bundleItem: {
          children: {
            instance: {
              children: {},
              pattern: ':bitemID',
            },
          },

          pattern: 'bitem',
        },

        bundles: {
          children: {
            instance: {
              children: {},
              pattern: ':bundleID',
            },
          },

          pattern: 'bundle',
        },

        cartItems: {
          children: {
            instance: {
              children: {
                answers: {
                  children: {},
                  pattern: 'answers',
                },

                changeStatus: {
                  children: {},
                  pattern: 'change_status',
                },
              },
              pattern: ':cartItemID',
            },
          },

          pattern: 'citem',
        },

        carts: {
          children: {
            instance: {
              children: {
                changeStatus: {
                  children: {},
                  pattern: 'change_status',
                },

                items: {
                  children: {},
                  pattern: 'items',
                },
              },

              pattern: ':cartID',
            },
          },

          pattern: 'cart',
        },

        categories: {
          children: {
            instance: {
              children: {
                subcategories: {
                  children: {},
                  pattern: 'children',
                },
              },

              pattern: ':categoryID',
            },

            top: {
              children: {},
              pattern: 'top',
            },
          },

          pattern: 'category',
        },

        dpc: {
          children: {
            category: {
              children: {
                instance: {
                  children: {
                    subcategories: {
                      children: {},
                      pattern: 'children',
                    },
                  },
                  pattern: ':categoryID',
                },
                top: {
                  pattern: 'top',
                },
              },
              pattern: 'category',
            },
            poption_set: {
              children: {
                instance: {
                  children: {},
                  pattern: ':familyID',
                },
              },
              pattern: 'poption_set',
            },
            products: {
              pattern: 'product',
            },
          },
          pattern: 'pdpc',
        },

        dpci: {
          pattern: 'dpci',
        },

        fulfillmentOptions: {
          children: {
            instance: {
              children: {},
              pattern: ':id',
            },
          },

          pattern: 'fulfillment_option',
        },

        productFamilies: {
          children: {
            instance: {
              children: {},
              pattern: ':pfamilyId',
            },
          },

          pattern: 'poption_set',
        },

        products: {
          children: {
            instance: {
              children: {
                changeStatus: {
                  children: {},
                  pattern: 'change_status',
                },
                defaultAnswers: {
                  children: {
                    instance: {
                      children: {},
                      pattern: ':answerID',
                    },
                  },
                  pattern: 'default_answers',
                },
                permissionGroups: {
                  children: {},
                  pattern: 'permission_groups',
                },
              },
              pattern: ':productID',
            },
          },

          pattern: 'product',
        },

        productsImages: {
          children: {
            instance: {
              children: {},
              pattern: ':imageID',
            },
          },

          pattern: 'product_image',
        },
      },

      pattern: 'catalog/v0.1',
    },

    espChat: {
      children: {
        adminChannels: {
          children: {
            instance: {
              children: {},
              pattern: ':channelID',
            },
          },
          pattern: 'admin_channels',
        },

        channels: {
          children: {
            instance: {
              children: {
                markRead: {
                  children: {},
                  pattern: 'mark_read',
                },
              },
              pattern: ':channelID',
            },

            totalUnread: {
              children: {},
              pattern: 'total_unread',
            },
          },

          pattern: 'channels',
        },

        directChannels: {
          children: {
            instance: {
              children: {
                markRead: {
                  children: {},
                  pattern: 'mark_read',
                },
              },
              pattern: ':channelID',
            },

            searchMessages: {
              children: {},
              pattern: 'search_messages',
            },

            totalUnread: {
              children: {},
              pattern: 'total_unread',
            },

            withUsers: {
              children: {},
              pattern: 'with_users',
            },
          },

          pattern: 'direct_channels',
        },

        messages: {
          children: {
            instance: {
              children: {
                click: {
                  pattern: 'click',
                },
              },
              pattern: ':messageID',
            },
          },
          pattern: 'messages',
        },

        scopedChannels: {
          children: {
            searchMessages: {
              children: {},
              pattern: 'search_messages',
            },
          },
          pattern: 'scoped_channels',
        },
      },

      pattern: 'chat/v0.1',
    },

    espConfig: {
      children: {
        attachmentAllowed: {
          children: {},
          pattern: 'attachments.allowed_extensions',
        },
        brandingAllowedExtensions: {
          children: {},
          pattern: 'branding.allowed_extensions',
        },
        dashboardConfig: {
          children: {},
          pattern: 'dashboard.ui_config',
        },
        defaultServiceTeam: {
          children: {},
          pattern: 'task.default_service_team',
        },
        deflectedCallCost: {
          children: {},
          pattern: 'deflected_cost_savings_coefficient',
        },
        elcEnableScheduledSyncConfig: {
          children: {},
          pattern: 'elc.enable_scheduled_sync',
        },
        firstDeploymentTime: {
          children: {},
          pattern: 'dashboard.first_deployment_time',
        },
        globalNotificationEmail: {
          children: {},
          pattern: 'notification.email.disable',
        },
        globalNotificationSMS: {
          children: {},
          pattern: 'notification.sms.disable',
        },
        kbDashboardConfig: {
          children: {},
          pattern: 'kb_dashboard.ui_config',
        },
        remoteUserCreateFlag: {
          children: {},
          pattern: 'remote_user_create_flag',
        },
        samlSpIdentityId: {
          children: {},
          pattern: 'saml_sp_identity_id',
        },
        subProductionConfiguration: {
          children: {},
          pattern: 'barista.subprod.url',
        },
        testEmailAddressVal: {
          children: {},
          pattern: 'notification.test_email_address_val',
        },
        testEmailEnabled: {
          children: {},
          pattern: 'notification.test_email_address_enabled',
        },
      },
      pattern: 'config/v0.1/configuration',
    },

    espGraphql: {
      children: {},
      pattern: 'graph',
    },

    espImage: {
      children: {
        profileImages: {
          children: {
            instance: {
              children: {},
              pattern: ':profileImageID',
            },
          },

          pattern: 'profile_image',
        },

        tenantImages: {
          children: {
            instance: {
              children: {},
              pattern: ':tenantImageID',
            },
          },

          pattern: 'tenant_images',
        },
      },

      pattern: 'images/v0.1',
    },

    espIntegration: {
      children: {
        confluence: {
          children: {
            lastSync: {
              pattern: 'last_sync',
            },
            reset: {
              pattern: 'cancel_scheduled_syncs',
            },
            runSync: {
              pattern: 'run_sync',
            },
            search: {
              pattern: 'search',
            },
            set: {
              pattern: 'set',
            },
          },
          pattern: 'confluence_kb',
        },

        genericTable: {
          pattern: 'generic_table',
        },

        integrations: {
          children: {
            confluence: {
              children: {
                setCredential: {
                  children: {},
                  pattern: 'set_credential',
                },
                testConnection: {
                  children: {},
                  pattern: 'is_active',
                },
              },
              // This eid is constant across tenants and will never change
              // Pepe sweared it on his blood
              pattern: '6ffd0f03-13ba-4d46-a7e8-fa92d2ef6b49',
            },

            instance: {
              children: {
                business_objects: {
                  pattern: 'business_objects',
                },
                search: {
                  pattern: 'search',
                },
              },
              pattern: ':integrationEID',
            },

            sharepoint: {
              children: {
                setCredential: {
                  children: {},
                  pattern: 'set_credential',
                },
                testConnection: {
                  children: {},
                  pattern: 'is_active',
                },
              },
              // This eid is constant across tenants and will never change
              // Pepe sweared it on his blood
              pattern: 'f6ff385e-da8d-42d8-a302-349895a9438f',
            },
            snowIntegration: {
              children: {
                setCredential: {
                  children: {},
                  pattern: 'set_credential',
                },

                status: {
                  children: {},
                  pattern: 'is_active',
                },
              },

              // This eid is constant across tenants and will never change
              // The New Guy sweared it on his blood
              pattern: '13578ca1-fd6d-4b2a-8b9d-08c91f4cdfc4',
            },
          },
          pattern: 'integrations',
        },

        servicenowKB: {
          children: {
            lastSync: {
              pattern: 'last_sync',
            },
            runSync: {
              pattern: 'run_sync',
            },
            set: {
              pattern: 'set',
            },
          },
          pattern: 'servicenow_kb',
        },
        sharepoint: {
          children: {
            lastSync: {
              pattern: 'last_sync',
            },
            reset: {
              pattern: 'cancel_scheduled_syncs',
            },
            runSync: {
              pattern: 'run_sync',
            },
            search: {
              pattern: 'search',
            },
            set: {
              pattern: 'set',
            },
          },
          pattern: 'sharepoint_kb',
        },
        zendesk: {
          children: {
            search: {
              pattern: 'search',
            },
            serve: {
              pattern: 'serve',
            },
          },
          pattern: 'zendesk_kb',
        },
      },

      pattern: 'integration/v0.1',
    },

    espNotification: {
      children: {
        desktopRegistration: {
          children: {
            pk: {
              children: {},
              pattern: ':pk',
            },
          },
          pattern: 'desktop_registration',
        },
        getAll: {
          children: {},
          pattern: 'notification',
        },
        notification: {
          children: {
            changeStatus: {
              pattern: 'change_status',
            },
            groups: {
              pattern: 'groups',
            },
            recall: {
              pattern: 'recall',
            },
            send: {
              pattern: 'send',
            },
            updateNotification: {
              pattern: 'update_notification',
            },
          },
          pattern: 'notification/:notificationID',
        },
        policy: {
          children: {
            features: {
              pattern: 'features',
            },
          },
          pattern: 'policy/:policyID?',
        },
        processingTime: {
          children: {},
          pattern: 'notification/announcements_proocessing_time',
        },
        sendSMS: {
          children: {},
          pattern: 'send_sms',
        },
        supportEmail: {
          children: {},
          pattern: 'support_email',
        },
      },

      pattern: 'notification/v0.1',
    },

    espPlaces: {
      children: {
        locations: {
          children: {
            instance: {
              children: {},
              pattern: ':locationID',
            },
          },

          pattern: 'locations',
        },

        types: {
          children: {},
          pattern: 'types',
        },
      },

      pattern: 'places/v0.1',
    },

    espRbac: {
      children: {
        entityPermissions: {
          children: {
            instance: {
              children: {},
              pattern: ':entityPermissionID',
            },
          },

          pattern: 'entity_permissions',
        },

        group: {
          children: {},
          pattern: 'group',
        },

        permissionGroups: {
          children: {
            instance: {
              children: {
                rule: {
                  children: {},
                  pattern: 'rule',
                },
              },

              pattern: ':permissionGroupID',
            },
          },

          pattern: 'group',
        },

        userRules: {
          children: {
            instance: {
              children: {
                entityFilters: {
                  children: {},
                  pattern: 'entity_filters',
                },
              },

              pattern: ':userRuleID',
            },
          },

          pattern: 'user_rule',
        },
      },

      pattern: 'rbac/v0.1',
    },

    espSearch: {
      children: {
        catalog: {
          children: {},
          pattern: 'typeahead/catalog',
        },

        directChats: {
          children: {},
          pattern: 'typeahead/chat/direct',
        },

        myEquipment: {
          children: {},
          pattern: 'typeahead/asset',
        },

        scopedChats: {
          children: {},
          pattern: 'typeahead/chat/scoped',
        },
        typeAhead: {
          children: {
            slick: {
              children: {},
              pattern: 'slick',
            },
          },

          pattern: 'typeahead/:model',
        },

        users: {
          children: {
            children: {},
            instance: ':searchValue',
          },
          pattern: 'typeahead/espuser.Espuser',
        },
      },

      pattern: 'search/v0.1',
    },

    espTenant: {
      children: {
        domain: {
          children: {
            instance: {
              children: {},
              pattern: ':domainID',
            },
          },

          pattern: 'domain',
        },

        tenantImages: {
          children: {},
          pattern: 'tenant_images',
        },

        tenants: {
          children: {
            instance: {
              children: {},
              pattern: ':tenantID',
            },
          },

          pattern: 'tenant',
        },
      },

      pattern: 'tenants/v0.1',
    },

    espTenantFiles: {
      children: {},
      pattern: 'tenant_files/v0.1',
    },

    espTodo: {
      children: {
        progress: {
          children: {
            escalate: {
              pattern: 'escalate',
            },
            instance: {
              children: {
                assignToDelegate: {
                  children: {},
                  pattern: 'assign_to_delegate',
                },

                changeState: {
                  children: {},
                  pattern: 'change_state',
                },
              },
              pattern: ':progessID',
            },
          },
          pattern: 'progress',
        },

        progressSummary: {
          children: {
            instance: {
              children: {
                changeStatus: {
                  children: {},
                  pattern: 'change_status',
                },
              },
              pattern: ':progressSummaryID',
            },

            managerView: {
              children: {},
              pattern: 'manager_view',
            },
          },
          pattern: 'progress_summary',
        },

        schedule: {
          children: {
            instance: {
              children: {
                changeStatus: {
                  pattern: 'change_status',
                },
                groups: {
                  pattern: 'groups',
                },
              },
              pattern: ':scheduleID',
            },
          },

          pattern: 'schedule',
        },

        scheduleInterval: {
          children: {
            instance: {
              children: {},
              pattern: ':scheduleIntervalID',
            },
          },

          pattern: 'schedule_interval',
        },

        scheduleToTemplate: {
          children: {
            instance: {
              children: {},
              pattern: ':scheduleToTemplatelID',
            },
          },

          pattern: 'schedule_to_template',
        },

        template: {
          children: {
            instance: {
              children: {
                changeStatus: {
                  pattern: 'change_status',
                },
              },
              pattern: ':templateID',
            },
          },
          pattern: 'template',
        },
      },

      pattern: 'todo/v0.1',
    },

    espUser: {
      children: {
        department: {
          children: {
            instance: {
              children: {},
              pattern: ':departmentID',
            },
          },

          pattern: 'department',
        },

        departmentType: {
          children: {},
          pattern: 'department_type',
        },

        device: {
          children: {
            apns: {
              children: {
                instance: {
                  children: {},
                  pattern: ':registrationID',
                },
              },
              pattern: 'apns',
            },

            gcm: {
              children: {
                instance: {
                  children: {},
                  pattern: ':registrationID',
                },
              },
              pattern: 'gcm',
            },
          },

          pattern: 'device',
        },

        emailAddressPolicies: {
          children: {
            instance: {
              children: {},
              pattern: ':policyID',
            },
          },

          pattern: 'email/address_policy',
        },

        jobRoles: {
          children: {
            instance: {
              children: {},
              pattern: ':jobRoleID',
            },

            permissions: {
              children: {},
              pattern: 'permissions',
            },
          },

          pattern: 'job_role',
        },

        presence: {
          children: {},
          pattern: 'presence',
        },

        users: {
          children: {
            bulkImportUsers: {
              children: {},
              pattern: 'bulk_import_users',
            },

            findUserRemoteId: {
              children: {},
              pattern: 'find_user_with_remote_id',
            },

            instance: {
              children: {
                activateUser: {
                  children: {},
                  pattern: 'activate_user',
                },

                addFavorite: {
                  children: {},
                  pattern: 'add_favorite',
                },

                addToGroup: {
                  children: {},
                  pattern: 'add_to_group',
                },

                changeDepartment: {
                  children: {},
                  pattern: 'change_department',
                },

                changeStartDate: {
                  children: {},
                  pattern: 'set_start_date',
                },

                deactivateUser: {
                  children: {},
                  pattern: 'deactivate_user',
                },

                favorites: {
                  children: {},
                  pattern: 'favorites',
                },

                getAssignmentForGroup: {
                  children: {},
                  pattern: 'get_contact_for_all_groups',
                },

                removeAssignmentFromGroup: {
                  children: {},
                  pattern: 'remove_assignment_from_group',
                },

                removeFavorite: {
                  children: {},
                  pattern: 'remove_favorite',
                },

                removeFromGroup: {
                  children: {},
                  pattern: 'remove_from_group',
                },

                resetPassword: {
                  children: {},
                  pattern: 'reset_password',
                },

                setManager: {
                  children: {},
                  pattern: 'set_manager',
                },
                setPassword: {
                  children: {},
                  pattern: 'set_password',
                },

                setUsername: {
                  children: {},
                  pattern: 'username',
                },

                takeAssignmentForGroup: {
                  children: {},
                  pattern: 'take_assignment_for_group',
                },

                takeAssignmentForGroupAdmin: {
                  children: {},
                  pattern: 'take_assignment_for_group_as_admin',
                },
                team: {
                  children: {},
                  pattern: 'team',
                },
                userSettings: {
                  children: {},
                  pattern: 'user_settings',
                },
              },

              pattern: ':userID',
            },

            sendOtp: {
              children: {},
              pattern: 'send_otp',
            },

            serviceAgents: {
              children: {},
              pattern: 'service_agents',
            },

            userCount: {
              children: {},
              pattern: 'user_count',
            },

            verifyOtp: {
              children: {},
              pattern: 'verify_otp',
            },
          },

          pattern: 'users',
        },

        whoAmI: {
          children: {},
          pattern: 'whoami',
        },
      },

      pattern: 'espuser/v0.1',
    },
    espUserGroups: {
      children: {
        filter: {
          children: {
            execute: {
              pattern: 'execute',
            },
          },
          pattern: 'adhoc_filter',
        },
        groups: {
          children: {
            instance: {
              children: {
                clone: {
                  pattern: 'clone',
                },
                members: {
                  pattern: 'members',
                },
                members_count: {
                  pattern: 'members_count',
                },
              },
              pattern: ':group_id',
            },
          },
          pattern: 'groups',
        },
      },
      pattern: 'groups/v0.1',
    },
    reporting: {
      children: {
        templates: {
          children: {
            barista_case_report: {
              pattern: 'barista_case_report',
            },
            barista_interactions_report: {
              pattern: 'barista_interactions_report',
            },
            call_deflection_csv: {
              pattern: 'call_deflection_csv',
            },
            call_deflection_report: {
              pattern: 'call_deflection_report',
            },
            helpful_barista_report: {
              pattern: 'helpful_barista_report',
            },
            keyword_interactions_report: {
              pattern: 'keyword_interactions_report',
            },
            reportID: {
              children: {},
              pattern: ':reportID/get_report',
            },
            surveys_report: {
              pattern: 'surveys_report',
            },
            top_intents_report: {
              pattern: 'top_intents_report',
            },
          },
          pattern: 'templates',
        },
      },
      pattern: 'reporting/v0.1',
    },

    serviceNowIntegration: {
      children: {
        custom: {
          children: {
            search: {
              children: {},
              pattern: 'search',
            },

            searchForChoices: {
              children: {},
              pattern: 'search_for_choices',
            },
          },
          pattern: 'custom',
        },

        defaultWorkflowMap: {
          children: {
            instance: {
              pattern: ':mapID',
            },
          },
          pattern: 'default_workflow_map',
        },
        proxy: {
          children: {
            getKey: {
              children: {},
              pattern: 'get_key',
            },
            serve: {
              children: {},
              pattern: 'serve',
            },
          },
          pattern: 'proxy',
        },
      },

      pattern: 'integration/servicenow/v0.1',
    },

    surveys: {
      children: {
        instance: {
          children: {
            action: {
              children: {},
              pattern: 'action',
            },
          },
          pattern: 'surveys/:surveyID',
        },
        surveys: {
          children: {
            downloadReport: {
              children: {},
              pattern: ':surveyID/download_report',
            },
            generateReport: {
              children: {},
              pattern: ':surveyID/generate_report',
            },
          },
          pattern: 'surveys',
        },
      },

      pattern: 'surveys/v0.1',
    },

    task: {
      children: {
        categories: {
          children: {
            instance: {
              children: {},
              pattern: ':categoryID',
            },
          },
          pattern: 'categories',
        },
        taskClassification: {
          children: {},
          pattern: 'task_classification',
        },
        taskNotes: {
          children: {
            instance: {
              children: {},
              pattern: ':taskNoteID',
            },
          },
          pattern: 'task_notes',
        },
        taskStatus: {
          children: {},
          pattern: 'task_status',
        },
        taskSubStatus: {
          children: {
            instance: {
              children: {},
              pattern: ':substateID',
            },
          },
          pattern: 'task_sub_status',
        },
        taskTypes: {
          children: {},
          pattern: 'task_types',
        },
        tasks: {
          children: {
            approveAll: {
              children: {},
              pattern: 'approve_all',
            },
            instance: {
              children: {
                approvalInfo: {
                  children: {},
                  pattern: 'approval_info',
                },
                baristaProposal: {
                  children: {},
                  pattern: 'barista_proposal',
                },
                cancelRequest: {
                  children: {},
                  pattern: 'cancel_request',
                },
                changeAuthor: {
                  children: {},
                  pattern: 'change_author',
                },
                changeOwner: {
                  children: {},
                  pattern: 'change_owner',
                },
                changeStatus: {
                  children: {},
                  pattern: 'change_status',
                },
                changeTeam: {
                  children: {},
                  pattern: 'change_team',
                },
                changeType: {
                  children: {},
                  pattern: 'change_type',
                },
                comment: {
                  children: {},
                  pattern: 'comment',
                },
                condition: {
                  children: {},
                  pattern: 'condition',
                },
                markReadAll: {
                  children: {},
                  pattern: 'mark_read_all',
                },
                messageCopy: {
                  children: {},
                  pattern: 'message_copy',
                },
                rejectReason: {
                  pattern: 'reject_reason',
                },
                relatedResponses: {
                  children: {},
                  pattern: 'related_responses',
                },
                sendCard: {
                  children: {},
                  pattern: 'send_card',
                },
                setApprovalQuestions: {
                  children: {},
                  pattern: 'set_approval_questions',
                },
                subscribe: {
                  children: {},
                  pattern: 'subscribe',
                },
                subscriptionScope: {
                  children: {},
                  pattern: 'subscription_scope',
                },
                unsubscribe: {
                  children: {},
                  pattern: 'unsubscribe',
                },
              },
              pattern: ':taskPK',
            },
            myHelpRequestTotalUnread: {
              children: {},
              pattern: 'my_help_requests_total_unread',
            },
            myHelpRequests: {
              children: {
                instance: {
                  children: {},
                  pattern: ':channelID',
                },
                totalUnread: {
                  children: {},
                  pattern: 'total_unread',
                },
              },
              pattern: 'my_help_requests',
            },
          },
          pattern: 'tasks',
        },
      },
      pattern: 'task/v0.1',
    },

    widgets: {
      children: {
        instance: {
          children: {
            action: {
              children: {},
              pattern: 'action',
            },
          },

          pattern: 'instances/:widgetID',
        },
      },

      pattern: 'widgets/v0.1',
    },
    workflow: {
      children: {
        conditionSets: {
          children: {},
          pattern: 'workflowconditionset',
        },

        conditions: {
          children: {
            instance: {
              children: {},
              pattern: ':conditionID',
            },
          },
          pattern: 'condition',
        },

        objectMappings: {
          children: {},
          pattern: 'object_mapping',
        },

        onboard: {
          children: {
            // onboardWorkflows.instance.getNext should be used instead
            //
            // getEntry: {
            // pattern  : 'get_entry',
            // children : {},
            // },
            //

            setEntry: {
              children: {},
              pattern: 'set_entry',
            },
          },

          pattern: 'onboard/:onboardID',
        },

        onboardWorkflows: {
          children: {
            instance: {
              children: {
                getNext: {
                  children: {},
                  pattern: 'get_next',
                },
              },

              pattern: ':workflowID',
            },
          },

          pattern: 'onboard_wf',
        },
        tasks: {
          children: {
            instance: {
              children: {},
              pattern: ':taskID',
            },
          },
          pattern: 'workflowtask',
        },

        workflowRequest: {
          children: {
            instance: {
              children: {
                getEntry: {
                  children: {},
                  pattern: 'get_entry',
                },

                setEntry: {
                  children: {},
                  pattern: 'set_entry',
                },

                setNextTask: {
                  children: {},
                  pattern: 'set_next_task',
                },

                setPrevTask: {
                  children: {},
                  pattern: 'set_prev_task',
                },
              },

              pattern: ':workflowRequestID',
            },
          },

          pattern: 'workflowrequest',
        },

        workflows: {
          children: {
            instance: {
              children: {
                sendNotification: {
                  children: {},
                  pattern: 'send_notification',
                },
              },
              pattern: ':workflowID',
            },
          },

          pattern: 'workflow',
        },
      },

      pattern: 'workflow/v0.1',
    },
  },

  pattern: 'api',
};

export default endpointPaths;
