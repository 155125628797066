import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useContext } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { boolean, number, object } from 'yup';
import GlobalContext from '../../../../../../globals/GlobalContext';
import APIcall from '../../../../../utils/APIcall';
import endpointGenerator from '../../../../../utils/endpointGenerator';
import InputDescription from '../../settings/CustomBrandingPage/InputDescription';

const conversationTimeout = 'conversation_timeout_mins';
const conversationTimeoutWarning = 'conversation_timeout_warning_mins';
const displayAnnouncements = 'display_announcements_on_app_home';
const displayApprovals = 'display_approvals_on_app_home';
const displayTasks = 'display_tasks_on_app_home';
const linkChatToHometab = 'link_from_chat_to_home_tab';
const showGdprDisclaimer = 'show_gdpr_disclaimer';
const taskMessageDelay = 'task_message_delay_in_seconds';

const labels = {
  [conversationTimeout]: 'Conversation Timeout (minutes)',
  [conversationTimeoutWarning]: 'Conversation Timeout Warning (minutes)',
  [displayAnnouncements]: 'Display Announcements in Home Tab',
  [displayApprovals]: 'Display Approvals in Home Tab',
  [displayTasks]: 'Display Tasks in Home Tab',
  [linkChatToHometab]: 'Link Chat to Home Tab',
  [showGdprDisclaimer]: 'Show GDPR Disclaimer',
  [taskMessageDelay]: 'Task Message Delay (seconds)',
};

const descriptions = {
  [conversationTimeout]:
    'Conversation resets if a user has not responded to an active conversation in {bot_name} in these many minutes',
  [conversationTimeoutWarning]:
    'Show a warning message to the user these many minutes before resetting',
  [displayAnnouncements]:
    'Whether or not announcements for a user should be shown on their Home tab.',
  [displayApprovals]: 'Whether or not approvals should be shown on Home tab.',
  [displayTasks]: 'Whether or not tasks should be shown on Home tab.',
  [linkChatToHometab]:
    'Open the Home button links in the Home tab within MS Teams. If false, the home button links will open in a web browser',
  [showGdprDisclaimer]:
    'Show a GDPR disclaimer at the beginning of every conversation with {bot_name}',
  [taskMessageDelay]:
    'Time in seconds to wait between showing task messages from {bot_name} after the first message is displayed. Default is 10 seconds',
};

const validationSchema = object().shape({
  [conversationTimeout]: number().min(1).required(),
  [conversationTimeoutWarning]: number()
    .required()
    .when(conversationTimeout, (conversationTimeoutValue, schema) => {
      if (conversationTimeoutValue === 1) {
        return schema.oneOf(
          [0, number().min(1)],
          'Timeout warning must be either 0 or higher than 1 if timeout is 1'
        );
      }
      return schema.min(
        1,
        'Timeout warning must be greater than or equal to 1'
      );
    }),
  [displayAnnouncements]: boolean().required(),
  [displayApprovals]: boolean().required(),
  [displayTasks]: boolean().required(),
  [linkChatToHometab]: boolean().required(),
  [showGdprDisclaimer]: boolean().required(),
  [taskMessageDelay]: number().min(1).required(),
});

const sdkPath = endpointGenerator.genPath(
  'espBaristaBot.integrations.instance',
  {
    botType: 'TEAMSSDK',
  }
);
const useTeamsSdkSettings = () => {
  return useQuery([sdkPath], async () => {
    const response = await APIcall.get({
      token: true,
      url: sdkPath,
    });

    return response.body;
  });
};

const usePatchTeamsSdkSettings = () => {
  return useMutation(async (values) => {
    await APIcall.patch({
      data: {
        integration_settings: {
          [conversationTimeout]: values[conversationTimeout],
          [conversationTimeoutWarning]: values[conversationTimeoutWarning],
          [displayAnnouncements]: values[displayAnnouncements],
          [displayApprovals]: values[displayApprovals],
          [displayTasks]: values[displayTasks],
          [linkChatToHometab]: values[linkChatToHometab],
          [showGdprDisclaimer]: values[showGdprDisclaimer],
          [taskMessageDelay]: values[taskMessageDelay],
        },
      },
      token: true,
      url: sdkPath,
    });
  });
};
const TeamsIntegrationSdkSettings = () => {
  const {
    tenant: { tenantInfo },
  } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const { data, isLoading } = useTeamsSdkSettings();
  const settings = data?.integration_settings;
  const { mutateAsync: patchSettings, isLoading: isPatchLoading } =
    usePatchTeamsSdkSettings();

  const handleReset = useCallback(() => {}, []);
  const handleSubmit = useCallback(
    async (/* object */ values, /* FormikActions<object> */ actions) => {
      if (!patchSettings) {
        return;
      }
      actions.setSubmitting(true);
      try {
        const changedValues = {
          ...settings,
        };
        if (values[conversationTimeout] !== settings[conversationTimeout]) {
          try {
            changedValues[conversationTimeout] = parseInt(
              values[conversationTimeout],
              10
            );
          } catch (error) {
            // ignore, should be caught by validation
          }
        }
        if (
          values[conversationTimeoutWarning] !==
          settings[conversationTimeoutWarning]
        ) {
          try {
            changedValues[conversationTimeoutWarning] = parseInt(
              values[conversationTimeoutWarning],
              10
            );
          } catch (error) {
            // ignore, should be caught by validation
          }
        }
        if (values[displayAnnouncements] !== settings[displayAnnouncements]) {
          changedValues[displayAnnouncements] = values[displayAnnouncements];
        }
        if (values[displayApprovals] !== settings[displayApprovals]) {
          changedValues[displayApprovals] = values[displayApprovals];
        }
        if (values[displayTasks] !== settings[displayTasks]) {
          changedValues[displayTasks] = values[displayTasks];
        }
        if (values[linkChatToHometab] !== settings[linkChatToHometab]) {
          changedValues[linkChatToHometab] = values[linkChatToHometab];
        }
        if (values[showGdprDisclaimer] !== settings[showGdprDisclaimer]) {
          changedValues[showGdprDisclaimer] = values[showGdprDisclaimer];
        }
        if (values[taskMessageDelay] !== settings[taskMessageDelay]) {
          try {
            changedValues[taskMessageDelay] = parseInt(
              values[taskMessageDelay],
              10
            );
          } catch (error) {
            // ignore, should be caught by validation
          }
        }
        await patchSettings(changedValues);

        await queryClient.invalidateQueries(sdkPath);
      } catch (error) {
        // eslint-disable-next-line no-console -- ok here
        console.error(error);
      }
      actions.resetForm();
      actions.setSubmitting(false);
    },
    [patchSettings, queryClient, settings]
  );

  if (!settings) {
    return <Loader />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        [conversationTimeout]: settings[conversationTimeout],
        [conversationTimeoutWarning]: settings[conversationTimeoutWarning],
        [displayAnnouncements]: settings[displayAnnouncements],
        [displayApprovals]: settings[displayApprovals],
        [displayTasks]: settings[displayTasks],
        [linkChatToHometab]: settings[linkChatToHometab],
        [showGdprDisclaimer]: settings[showGdprDisclaimer],
        [taskMessageDelay]: settings[taskMessageDelay],
      }}
      onReset={handleReset}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Grid as={Segment} attached='bottom' loading={isLoading}>
          <Grid.Row columns='equal' style={{ paddingLeft: '1em' }}>
            <Grid.Column>
              <Segment basic>
                <Form>
                  <Form.Field
                    error={formikProps.errors[displayAnnouncements]}
                    style={{ flexDirection: 'column', marginTop: '1em' }}
                  >
                    <Checkbox
                      checked={formikProps.values[displayAnnouncements]}
                      fitted
                      label={labels[displayAnnouncements]}
                      name={displayAnnouncements}
                      // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                      onChange={() =>
                        formikProps.setFieldValue(
                          displayAnnouncements,
                          !formikProps.values[displayAnnouncements]
                        )
                      }
                    />
                    <InputDescription
                      description={descriptions[displayAnnouncements]}
                    />
                  </Form.Field>

                  <Form.Field
                    error={formikProps.errors[displayApprovals]}
                    style={{ flexDirection: 'column', marginTop: '1em' }}
                  >
                    <Checkbox
                      checked={formikProps.values[displayApprovals]}
                      fitted
                      label={labels[displayApprovals]}
                      name={displayApprovals}
                      // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                      onChange={() =>
                        formikProps.setFieldValue(
                          displayApprovals,
                          !formikProps.values[displayApprovals]
                        )
                      }
                    />
                    <InputDescription
                      description={descriptions[displayApprovals]}
                    />
                  </Form.Field>

                  <Form.Field
                    error={formikProps.errors[displayTasks]}
                    style={{ flexDirection: 'column', marginTop: '1em' }}
                  >
                    <Checkbox
                      checked={formikProps.values[displayTasks]}
                      fitted
                      label={labels[displayTasks]}
                      name={displayTasks}
                      // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                      onChange={() =>
                        formikProps.setFieldValue(
                          displayTasks,
                          !formikProps.values[displayTasks]
                        )
                      }
                    />
                    <InputDescription
                      description={descriptions[displayTasks]}
                    />
                  </Form.Field>

                  <Form.Field
                    error={formikProps.errors[linkChatToHometab]}
                    style={{ flexDirection: 'column', marginTop: '1em' }}
                  >
                    <Checkbox
                      checked={formikProps.values[linkChatToHometab]}
                      fitted
                      label={labels[linkChatToHometab]}
                      name={linkChatToHometab}
                      // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                      onChange={() =>
                        formikProps.setFieldValue(
                          linkChatToHometab,
                          !formikProps.values[linkChatToHometab]
                        )
                      }
                    />
                    <InputDescription
                      description={descriptions[linkChatToHometab]}
                    />
                  </Form.Field>

                  <Form.Field
                    error={formikProps.errors[showGdprDisclaimer]}
                    style={{ flexDirection: 'column', marginTop: '1em' }}
                  >
                    <Checkbox
                      checked={formikProps.values[showGdprDisclaimer]}
                      fitted
                      label={labels[showGdprDisclaimer]}
                      name={showGdprDisclaimer}
                      // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                      onChange={() =>
                        formikProps.setFieldValue(
                          showGdprDisclaimer,
                          !formikProps.values[showGdprDisclaimer]
                        )
                      }
                    />
                    <InputDescription
                      description={descriptions[showGdprDisclaimer].replace(
                        '{bot_name}',
                        tenantInfo?.branding_obj?.chatbot_name || 'Barista'
                      )}
                    />
                  </Form.Field>

                  <Form.Field
                    error={formikProps.errors[conversationTimeout]}
                    style={{ flexDirection: 'column', marginTop: '1em' }}
                  >
                    <label>{labels[conversationTimeout]}</label>

                    <Input
                      id={conversationTimeout}
                      name={conversationTimeout}
                      // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                      onChange={(event, data) =>
                        formikProps.setFieldValue(
                          conversationTimeout,
                          data.value.replace(/\s/g, '')
                        )
                      }
                      type='number'
                      value={formikProps.values[conversationTimeout]}
                    />
                    <InputDescription
                      description={descriptions[conversationTimeout].replace(
                        '{bot_name}',
                        tenantInfo?.branding_obj?.chatbot_name || 'Barista'
                      )}
                    />
                  </Form.Field>

                  <Form.Field
                    error={formikProps.errors[conversationTimeoutWarning]}
                    style={{ flexDirection: 'column', marginTop: '1em' }}
                  >
                    <label>{labels[conversationTimeoutWarning]}</label>

                    <Input
                      id={conversationTimeoutWarning}
                      name={conversationTimeoutWarning}
                      // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                      onChange={(event, data) =>
                        formikProps.setFieldValue(
                          conversationTimeoutWarning,
                          data.value.replace(/\s/g, '')
                        )
                      }
                      type='number'
                      value={formikProps.values[conversationTimeoutWarning]}
                    />
                    <InputDescription
                      description={descriptions[conversationTimeoutWarning]}
                    />
                  </Form.Field>
                  <Form.Field
                    error={formikProps.errors[taskMessageDelay]}
                    style={{ flexDirection: 'column', marginTop: '1em' }}
                  >
                    <label>{labels[taskMessageDelay]}</label>

                    <Input
                      id={taskMessageDelay}
                      name={taskMessageDelay}
                      // eslint-disable-next-line react/jsx-no-bind -- its ok to use bind here
                      onChange={(event, data) =>
                        formikProps.setFieldValue(
                          taskMessageDelay,
                          data.value.replace(/\s/g, '')
                        )
                      }
                      type='number'
                      value={formikProps.values[taskMessageDelay]}
                    />
                    <InputDescription
                      description={descriptions[taskMessageDelay].replace(
                        '{bot_name}',
                        tenantInfo?.branding_obj?.chatbot_name || 'Barista'
                      )}
                    />
                  </Form.Field>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Segment attached='bottom' secondary textAlign='right'>
            <Button
              content='Cancel'
              disabled={!formikProps.dirty}
              id='cancel_button'
              onClick={formikProps.handleReset}
              type='button'
            />
            <Button
              content='Save'
              disabled={
                formikProps.isSubmitting ||
                !formikProps.dirty ||
                !formikProps.isValid
              }
              id='save_button'
              loading={isPatchLoading}
              onClick={formikProps.handleSubmit}
              primary
              type='button'
            />
          </Segment>
        </Grid>
      )}
    </Formik>
  );
};

export default TeamsIntegrationSdkSettings;
