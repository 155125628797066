import React, { Component } from 'react';
import { createComponent, ThemeProvider } from 'react-fela';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Dimmer, Loader } from 'semantic-ui-react';
import { debounce, hasIn, isString, noop } from 'lodash';

import ChatFormAttachments from './ChatFormAttachments';
import ChatFormTextArea from './ChatFormTextArea';
import ChatFormImg from './ChatFormImg';
import ChatFormFile from './ChatFormFile';
import ChatFormToggleAttach from './ChatFormToggleAttach';
import ChatFormSendButton from './ChatFormSendButton';
import ChatFormSelect from './ChatFormSelect';
import ChatFormSelectToggle from './ChatFormSelectToggle';

const rule = (props) => {
  const { theme } = props;

  return {
    backgroundColor: theme.colors.gray,
    borderColor: theme.colors.darkGray,
    borderStyle: 'solid',
    borderWidth: '1px 0 0 0',
    bottom: 0,
    // overflow        : 'hidden',
    position: 'absolute',
    width: '100%',
    zIndex: 10,
  };
};

// NOTE: These values are included in the component theme wrapper below
const chatFormTheme = {
  expandDuration: '300ms',
  expandTimingFunction: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  formHeight: '44px',
  sendTimingFunction: 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',
};

const SEARCH_TIMEOUT = 300;

class ChatForm extends Component {
  static propTypes = {
    allowedExtensions: PropTypes.string,
    attachmentFileName: PropTypes.string,
    attachmentImgSrc: PropTypes.string,
    autoFocus: PropTypes.bool,

    caseTaskId: PropTypes.number,

    channelId: PropTypes.string, // passed directly. If passed then it's assumed this chat belongs to a case
    className: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
    disabled: PropTypes.bool,

    enableOnlyQRScanner: PropTypes.bool,

    // Allow to disable the ENTER key down to submit the msg
    enableSubscribe: PropTypes.bool,
    handleChange: PropTypes.func,
    handleDeleteAttachment: PropTypes.func,

    handleQRScannned: PropTypes.func,

    isLoadingAttachment: PropTypes.bool,

    isLoadingOptions: PropTypes.bool,

    isSending: PropTypes.bool,

    onFileSelect: PropTypes.func,

    onOpenSubscribers: PropTypes.func,
    onSearchChange: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onOptionClick: PropTypes.func.isRequired,
        secondarylabel: PropTypes.string,
      })
    ),
    placeholder: PropTypes.string,
    sendMessage: PropTypes.func,
  };

  static defaultProps = {
    allowedExtensions: null,
    attachmentFileName: null,
    attachmentImgSrc: null,
    autoFocus: false,
    caseTaskId: void 0,
    channelId: '',
    defaultMessage: '',
    disabled: false,
    enableOnlyQRScanner: false,
    enableSubscribe: false,
    handleChange: noop,
    handleDeleteAttachment: noop,
    handleQRScannned: noop,
    isLoadingAttachment: false,
    isLoadingOptions: false,
    isSending: false,
    onFileSelect: noop,
    onOpenSubscribers: noop,
    onSearchChange: noop,
    options: void 0,
    placeholder: '',
    sendMessage: noop,
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    filteredOptions: this.props.options || void 0,
    isAttachmentMenuOpen: false,
    isDropDownInitialized: false, // only opens the dropdown until this is true
    isOptionSelected: false,
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    isSending: this.props.isSending,
    isTextareaFocused: false,
    // eslint-disable-next-line react/destructuring-assignment -- risky to fix
    value: this.props.defaultMessage ? this.props.defaultMessage : '',
  };

  componentDidMount() {
    this._isMounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultMessage, options } = this.props;

    // for this the next prop default message must NOT be empty
    // and a change must have been occured
    if (
      nextProps.defaultMessage &&
      nextProps.defaultMessage !== defaultMessage
    ) {
      this.setState({
        value: nextProps.defaultMessage,
      });
    }

    // immediatly update filteredOptions when options prop change
    if (nextProps.options !== options) {
      this.setState({
        filteredOptions: nextProps.options,
      });
    }
  }

  componentDidUpdate() {
    this.handleScrollSelectedItemIntoView();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  menuRef = React.createRef();

  handleAttachementMenuOpen = () => {
    const { isAttachmentMenuOpen } = this.state;
    this.setState({
      isAttachmentMenuOpen: !isAttachmentMenuOpen,
    });
  };

  handleTextAreaFocus = (e) => {
    const { value } = this.state;

    // prevents blowing up on HMR
    if (!this._isMounted) {
      return;
    }
    const { handleChange } = this.props;

    if (e.type === 'focus') {
      e.target.value.length &&
        this.setState({
          isTextareaFocused: true,
        });
    } else if (e.type === 'blur') {
      this.setState({
        isTextareaFocused: Boolean(e.target.value),
      });
    } else if (e.target.value.length || value !== e.target.value) {
      e.preventDefault();
      const input = this.textCrop(e.target.value);

      this.setState(
        {
          isTextareaFocused: Boolean(e.target.value),
          value: input,
        },
        () => {
          handleChange(input);
        }
      );
    } else {
      this.setState({
        isTextareaFocused: false,
      });
    }
  };

  handleSend = (e) => {
    const { attachmentFileName, attachmentImgSrc, sendMessage } = this.props;

    const { selectedOptionId, value } = this.state;

    e.preventDefault();

    const inputText = value;
    if (
      isString(inputText) &&
      inputText.replace(/\s/g, '') === '' &&
      !attachmentImgSrc &&
      !attachmentFileName
    ) {
      return;
    }

    // sends the selected option only if exists
    sendMessage(inputText, selectedOptionId);

    // Set the button style to sending
    this.setState({
      isSending: true,
      isTextareaFocused: false,
      selectedOptionId: null,
      value: '',
    });

    // We cannot change the state back after sending any sooner than 1500ms
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({
          isSending: false,
        });
      }
    }, 1500);
  };

  handleSendFirstOption = (e) => {
    const { options } = this.props;
    e.preventDefault();

    // just send the first option
    if (hasIn(options, '0.onOptionClick')) {
      const [firstOption] = options;

      firstOption.onOptionClick();

      this.setState({
        isOptionSelected: true,
        selectedOptionId: firstOption.id,
        value: firstOption.label,
      });
    }
  };

  /**
   *
   * Always crops text to the limit size
   * Useful when user forces a large input via copy pasta
   */
  textCrop = (value) => {
    const limit = 10000; // ten thousand should do for now
    return value.substr(0, limit);
  };

  handleKeyDown = (e) => {
    const { disabled, options } = this.props;
    const { isOptionSelected } = this.state;

    if (!disabled) {
      if (e.keyCode === 38) {
        // Arrow up
        // this.handleOptionMoveUp();
        this.handleArrowKeyMove('up');
      }
      if (e.keyCode === 40) {
        // Arrow down
        // this.handleOptionMoveDown();
        this.handleArrowKeyMove('down');
      }

      // if (e.keyCode === 13 && e.ctrlKey &&) { //send on Enter+ctrl
      if (e.keyCode === 13 && !e.shiftKey) {
        // send on Enter (but not  on enter+shift)

        if (options && !isOptionSelected) {
          this.handleSendFirstOption(e);
        } else {
          this.handleSend(e);
        }
      }
    }
  };

  handleScrollSelectedItemIntoView = () => {
    const { filteredOptions } = this.state;

    if (this.menuRef && filteredOptions && filteredOptions.length > 0) {
      // eslint-disable-next-line react/no-find-dom-node -- TODO refactor
      const menu = ReactDOM.findDOMNode(this.menuRef.current);
      if (menu) {
        const item = menu.querySelector('[aria-selected="true"]');

        if (item) {
          const isOutOfUpperView = item.offsetTop < menu.scrollTop;
          const isOutOfLowerView =
            item.offsetTop + item.clientHeight >
            menu.scrollTop + menu.clientHeight;

          if (isOutOfUpperView) {
            menu.scrollTop = item.offsetTop;
          } else if (isOutOfLowerView) {
            menu.scrollTop =
              item.offsetTop + item.clientHeight - menu.clientHeight;
          }
        }
      }
    }
  };

  getObjectById = (id) => {
    const { options } = this.props;
    return id ? options.findIndex((opt) => opt.id === id) : 0;
  };

  // When options are present, move the selected option up or down one.
  handleArrowKeyMove = (dir) => {
    const { options } = this.props;
    const { isOptionSelected } = this.state;

    if (options) {
      this.setState({
        isDropDownInitialized: true,
      });
    }

    if (isOptionSelected) {
      this.setState({
        isOptionSelected: false,
      });
    }

    if ((dir === 'up' || dir === 'down') && options) {
      this.setState((prevState) => {
        const previousIndex = prevState.selectedOptionId
          ? options.findIndex(
              (prevOpt) => prevOpt.id === prevState.selectedOptionId
            )
          : 0;
        let newIndex;
        if (dir === 'up') {
          newIndex =
            previousIndex === 0 ? options.length - 1 : previousIndex - 1;
        } else if (dir === 'down') {
          newIndex =
            previousIndex === options.length - 1 ? 0 : previousIndex + 1;
        } else {
          throw new Error(
            'handleArrowKeyMove() requires a parameter of either "up" or "down"'
          );
        }
        options[newIndex].onOptionClick();
        // this.handleScrollSelectedItemIntoView();

        return {
          selectedOptionId: options[newIndex].id,
          value: options[newIndex].label,
        };
      });
    }
  };

  handleFileSelected = (file, regularFile) => {
    const { onFileSelect } = this.props;
    this.handleAttachementMenuOpen(); // Close ChatFormAttachments
    onFileSelect(file, regularFile); // Preload the file
  };

  handleSelectToggle = () => {
    const { isDropDownInitialized, isOptionSelected } = this.state;

    if (!isDropDownInitialized) {
      this.setState({
        isDropDownInitialized: true,
      });
    } else {
      this.setState({
        isOptionSelected: !isOptionSelected,
      });
    }
  };

  handleOptionChangeOnClick = (id) => {
    const { options } = this.props;
    const optionObjectId = this.getObjectById(id);

    this.setState({
      isOptionSelected: true,
      selectedOptionId: id,
      value: options[optionObjectId].label,
    });
  };

  handleSearch = debounce(() => {
    // prevents blowing up on HMR
    if (!this._isMounted) {
      return;
    }
    // console.warn('handleSearch()');
    const { options, onSearchChange } = this.props;
    const { value } = this.state;

    if (isString(value) && value.length === 0) {
      this.setState({
        filteredOptions: options,
      });
    } else {
      if (options) {
        this.setState({
          isDropDownInitialized: true,
          isOptionSelected: false,
        });

        const isInObject = (obj) => {
          const lowerCaseValue = value.toLowerCase();
          // Make sure we return an empty string for this object split if there
          // is no value in label parameter. That way the search API will return
          // more users if we get bad data, instead of blowing up.
          const lowerCaseLabel = obj.label ? obj.label.toLowerCase() : '';
          const splitLabel = lowerCaseLabel.split(' ');

          return splitLabel.find((str) => str.startsWith(lowerCaseValue));
        };
        const newOptions = options.filter(isInObject);

        this.setState({
          filteredOptions: newOptions,
        });
      }
    }

    onSearchChange(value);
  }, SEARCH_TIMEOUT);

  render() {
    const {
      allowedExtensions,
      attachmentFileName,
      attachmentImgSrc,
      autoFocus,
      caseTaskId,
      channelId,
      className,
      disabled,
      enableOnlyQRScanner,
      enableSubscribe,
      handleDeleteAttachment,
      handleQRScannned,
      isLoadingAttachment,
      isLoadingOptions,
      onOpenSubscribers,
      options,
      placeholder,
    } = this.props;

    const {
      filteredOptions,
      isAttachmentMenuOpen,
      isSending,
      isTextareaFocused,
      selectedOptionId,
      value,
      isOptionSelected,
      isDropDownInitialized,
    } = this.state;

    const placeholderSel = options ? 'Type to search...' : placeholder;

    return (
      <ThemeProvider theme={chatFormTheme}>
        <div className={className} data-component='ChatForm' id='ChatForm'>
          {options ? (
            <ChatFormSelect
              onOptionChange={this.handleOptionChangeOnClick}
              open={!isOptionSelected && isDropDownInitialized}
              options={filteredOptions}
              ref={this.menuRef}
              value={selectedOptionId}
            />
          ) : null}
          <div
            style={{
              overflow: 'hidden',
              position: 'relative',
              width: '100%',
            }}
          >
            {isLoadingAttachment && (
              <Dimmer active inverted>
                <Loader inverted />
              </Dimmer>
            )}
            {!attachmentImgSrc && !attachmentFileName ? (
              <>
                {options ? null : (
                  <ChatFormToggleAttach
                    disabled={disabled}
                    isAttachmentMenuOpen={isAttachmentMenuOpen}
                    isTextareaFocused={isTextareaFocused}
                    onToggleAttach={this.handleAttachementMenuOpen}
                  />
                )}
                <ChatFormTextArea
                  // eslint-disable-next-line jsx-a11y/no-autofocus -- legacy feature expecting autofocus
                  autoFocus={autoFocus}
                  disabled={disabled}
                  hasToggleButton={Boolean(options)}
                  isAttachmentMenuOpen={isAttachmentMenuOpen}
                  isLoading={isLoadingOptions}
                  isTextareaFocused={isTextareaFocused || Boolean(options)}
                  onChange={this.handleSearch}
                  onKeyDown={this.handleKeyDown}
                  onTextAreaFocus={this.handleTextAreaFocus}
                  placeholder={placeholderSel}
                  value={value}
                />
                {options ? (
                  <ChatFormSelectToggle
                    onClick={this.handleSelectToggle}
                    open={!isOptionSelected && isDropDownInitialized}
                  />
                ) : null}
              </>
            ) : null}
            {attachmentImgSrc && !attachmentFileName ? (
              <ChatFormImg
                deleteAttachment={handleDeleteAttachment}
                src={attachmentImgSrc}
              />
            ) : null}
            {attachmentFileName && !attachmentImgSrc ? (
              <ChatFormFile
                deleteAttachment={handleDeleteAttachment}
                fileName={attachmentFileName}
              />
            ) : null}
            <ChatFormSendButton
              disabled={
                disabled || Boolean(options && (!isOptionSelected || !value))
              }
              isLoading={isSending}
              onClick={this.handleSend}
            />
            {options ? null : (
              <ChatFormAttachments
                allowedExtensions={allowedExtensions}
                caseTaskId={caseTaskId}
                channelId={channelId}
                enableOnlyQRScanner={enableOnlyQRScanner}
                enableSubscribe={enableSubscribe}
                handleQRScannned={handleQRScannned}
                onFileSelect={this.handleFileSelected}
                onOpenSubscribers={onOpenSubscribers}
                open={isAttachmentMenuOpen}
              />
            )}
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default createComponent(rule, ChatForm, [
  'allowedExtensions',
  'attachmentFileName',
  'attachmentImgSrc',
  'autoFocus',
  'caseTaskId',
  'channelId',
  'defaultMessage',
  'disabled',
  'enableOnlyQRScanner',
  'enableSubscribe',
  'handleChange',
  'handleDeleteAttachment',
  'handleQRScannned',
  'isLoadingAttachment',
  'isLoadingOptions',
  'isSending',
  'onFileSelect',
  'onOpenSubscribers',
  'onSearchChange',
  'options',
  'placeholder',
  'sendMessage',
]);
