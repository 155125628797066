import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Konami from 'react-konami';

// Controllers
import AppController from './v1/components/controllers/AppController';

// Atoms
import IncompatibleModal from './v2/components/functional/IncompatibleModal';

// Organisms
import ToastNotifications from './v1/components/organisms/ToastNotifications';

// Util
import { AppProvider } from './v1/utils/AppContext';

// Packages
import intlProvider from 'esp-util-intl';

import iphoneXOverlay from '../../lib/images/iphone-812h-safe@3x.png';
import UnreadCount from './UnreadCount';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import BodyClassName from './globals/BodyClassName';
import AppGlobalContextProvider from './AppGlobalContextProvider';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
/**
 * Main App component
 * Notice that this component can be mounted when user is logged out or even in tenant select
 * Don't use it for thunks that require authentication or even tenant
 * Prefere HomePage or AdminPage for that
 */

class App extends PureComponent {
  static propTypes = {
    appVersion: ImmutablePropTypes.map,
    canPlayNotificationsSounds: PropTypes.bool,
    checkTenantDomain: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    currentUserID: PropTypes.number,
    // All of these props should come from controller
    // Leaving them as non-required for testability
    dispatchNotification: PropTypes.func,
    domainLoaded: PropTypes.bool,
    errorTenant: PropTypes.bool,
    getTenant: PropTypes.func,
    isCompatible: PropTypes.bool,
    isLoading: PropTypes.bool,
    locale: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({}),
    }),
    tenantLoaded: PropTypes.bool,
    tenantName: PropTypes.string,
    unreadCount: PropTypes.number,
  };

  static defaultProps = {
    appVersion: Immutable.Map(),
    canPlayNotificationsSounds: true,
    checkTenantDomain: _.noop,
    currentUserID: '',
    dispatchNotification: _.noop,
    domainLoaded: false,
    errorTenant: false,
    getTenant: _.noop,
    isCompatible: true,
    // default value it's true because we don't want scare people out
    isLoading: false,
    locale: '',
    location: {
      pathname: '',
    },
    tenantLoaded: false,
    tenantName: 'Espressive',
    unreadCount: null,
  };

  componentDidMount() {
    const {
      checkTenantDomain,
      domainLoaded,
      getTenant,
      isLoading,
      locale,
      location,
      tenantLoaded,
    } = this.props;

    if (!isLoading && !domainLoaded) {
      checkTenantDomain();
    }

    /** Load tenant info only if the domain has been positively checked before */
    if (!isLoading && domainLoaded && !tenantLoaded) {
      const { pathname } = location;
      getTenant(pathname);
    }
    intlProvider(locale);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      domainLoaded,
      errorTenant,
      getTenant,
      location,
      isLoading,
      tenantLoaded,
    } = nextProps;
    const { locale } = this.props;
    /**
     * Load tenant info only if the domain has been positively checked before
     * and no tenant API check has been done
     * with no tenant error
     */
    if (!isLoading && domainLoaded && !tenantLoaded && !errorTenant) {
      const { pathname } = location;
      getTenant(pathname);
    }

    if (locale !== nextProps.locale) {
      intlProvider(nextProps.locale);
    }
  }

  onHandleKeyStroke = () => {
    const { appVersion, dispatchNotification } = this.props;

    const versionInfo =
      `Client ID: ${appVersion.get('clientId')}\n` +
      `System ID: ${appVersion.get('systemId')}`;
    dispatchNotification(versionInfo);
  };

  render() {
    const {
      canPlayNotificationsSounds,
      children,
      // tenantHeaderColor,
      tenantName,
      isCompatible,
      location,
      // tenantPrimaryColor,
      unreadCount,
      currentUserID,
    } = this.props;

    const iphoneOverlayStyles = {
      display: 'none',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 99999,
    };

    const testStupidPhone = false;

    if (testStupidPhone) {
      document.body.id = 'os-ios';
    }

    const { query } = location;

    // Moves the fix for DEV-16255 here
    // Since we only need this in web
    const isWeb = document.querySelector('body#web');
    const helmetProps = {
      title: 'Home',
      titleTemplate:
        unreadCount > 0
          ? `(${unreadCount}) %s - ${tenantName}`
          : `%s - ${tenantName}`,
    };

    return (
      <BodyClassName className='esp'>
        <AppProvider value={query}>
          <QueryClientProvider client={queryClient}>
            <AppGlobalContextProvider currentUserID={currentUserID}>
              <div className='app-root'>
                {window.electron ? (
                  <div
                    style={
                      // Makes the header draggable in the electron window
                      {
                        height: '1.25em',
                        position: 'absolute',
                        top: 0,
                        webkitAppRegion: 'drag',
                        width: '100%',
                        zIndex: 999999,
                      }
                    }
                  />
                ) : null}
                {testStupidPhone && (
                  <img
                    alt='iphone-overlay'
                    className='x-overlay'
                    src={iphoneXOverlay}
                    style={iphoneOverlayStyles}
                  />
                )}

                <UnreadCount
                  canPlayNotificationsSounds={canPlayNotificationsSounds}
                  unreadCount={unreadCount}
                />
                {isWeb ? (
                  <Helmet {...helmetProps}>
                    <base href='/' />
                  </Helmet>
                ) : (
                  <Helmet {...helmetProps} />
                )}

                {children}

                <ToastNotifications />

                <Konami
                  easterEgg={this.onHandleKeyStroke}
                  konami={[17, 86, 69, 82]} // CTRL + V + E + R
                />

                {!isCompatible && <IncompatibleModal />}
              </div>
            </AppGlobalContextProvider>
          </QueryClientProvider>
        </AppProvider>
      </BodyClassName>
    );
  }
}

export const AppTest = App;

export default AppController(App);
