/* eslint-disable sort-keys  -- the order of this file is important for buildUIConfig.tests*/
const featureFlagOptions = {
  displayMenu: {
    approvalsMainMenu: false,
    casesMainMenu: false,
    customBranding: false,
    disableDirectory_disableLinksToProfiles: false,
    disableDirectory_hideContacts: false,
    disableDirectory_hideMyMessages: false,
    disableDirectory_disableProfilePhotoSelection: false,
    disable_ga: false,
    full_edit_profile: false,
    myMessagesLastMsg_applyOnlyToRequests: false,
    myMessagesLastMsg_customName: '',
    myMessagesLastMsg_disableAllNames: false,
    myMessagesLastMsg_onlyFirstName: true,
    myMessagesLastMsg_onlyLastName: false,
    myMessagesLastMsg_useLastMessageDefaultIntl: false,
    my_equipment: false,
    my_requests: false,
    publicEmployeeNumber: false,
    reports: false,
    store: false,
    tickets: true,
  },
  helpSettings: {
    chat: false,
    chatv2: false,
    directory: false,
    email: false,
    profile: false,
    my_equipment: false,
    store: false,
  },
  integrations: {
    azure: true,
    confluence: true,
    csv: true,
    email: true,
    okta: true,
    servicenow: false,
    sharepoint: true,
    teams: true,
  },
  newFeature: {
    // used for testing purposes
    activities: false,
    addEmployee: false,
    adminUsers: false,
    announcementSurveyBuilder: false,
    approvalRules: false,
    approvals: false,
    baristaImpersonator: false,
    baristaSearchV2: false,
    customLandingPath: '',
    emailIntegrationCase: false,
    exchange_oauth: false,
    kbIntentAnnotation: false,
    largeBaristaWindow: false,
    launchExistingEmployeeWF: false,
    locations: false,
    offlineMessage: false,
    saveInteractionsDashboardAsPdf: false,
    serviceDepartmentCaseManagement: false,
    serviceRequest: false,
    servicenowContentSyncTab: false,
    newDashboardOrganization: false,
    kbDashboardV2: false,
  },
  desktopApp: {
    updateServerUrl: {},
  },
  card_actions_config: {
    approved: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    archived: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    cancelled_by_agent: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    cancelled_by_user: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    cannot_reproduce: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    closed: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    duplicate: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    enable_feature: true,
    learned: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    open: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    pending_approval: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    rejected: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    resolved: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    submitted: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
    validated: {
      customize: false,
      cancel: true,
      comment: true,
      delete: true,
      nudge: true,
    },
  },

  privacystatementsettings: [],

  notifications_config: {
    sounds_enabled: true,
  },
  default_sorting: {
    default_sort_by: '',
    homefeed: false,
  },
  messages: {
    prohibit_chat_autoScrolling: false,
    allow_expandable_chatMessages_feature: true,
  },
};

/* eslint-enable */

export default Object.freeze(featureFlagOptions);
