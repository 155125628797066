import { Dropdown, Image, Menu } from 'semantic-ui-react';
import { Link, NavLink, Redirect } from 'react-router-dom';
import React, { lazy, PureComponent } from 'react';
import TeamsIntegrationSdkSettings from './admin/integrations/teams/TeamsIntegrationSdkSettings';

import AdminFiles from './admin/settings/AdminFiles';
import AdminHelp from './admin/settings/AdminHelp/AdminHelp';
// Controller
import AdminPageController from '../controllers/AdminPageController';
import AdminProductFamily from '../molecules/AdminProductFamily';
import AdminProductFulfillment from '../molecules/AdminProductFulfillment';
import AdminProductPermissionsContext from '../molecules/AdminProductPermissionsContext';
import AdminProductSpecs from '../molecules/AdminProductSpecs';
import AdminServiceDepartmentDetailCaseManagement from './admin/serviceDepartment/DetailsCaseManagement';
import AdminServiceDetailCategories from './admin/serviceDepartment/AdminServiceDetailCategories';
import AdminServiceDetailTeams from './admin/serviceDepartment/AdminServiceDetailTeams';
import AdminServiceTeamLocation from './admin/serviceTeams/AdminServiceTeamLocation';
import AdminServiceTeamMembers from './admin/serviceTeams/AdminServiceTeamMembers';
import AdminServiceTeamSetting from './admin/serviceTeams/AdminServiceTeamSetting';
import AdminServiceTeamSubstates from './admin/serviceTeams/AdminServiceTeamSubstates';
import { AdminStructure } from 'cascara-middleware';
// Molecules
import AdminTitle from '../molecules/AdminTitle';
// import approvalRulesIcon from '@iconify/icons-bx/bx-list-ul';
import authenticationIcon from '@iconify/icons-bx/bx-id-card';
import bundlesIcon from '@iconify/icons-bx/bx-basket';
import CaseInboxAccount from '../molecules/CaseInboxAccount';
import checkMenuPermissions from '../../utils/checkMenuPermissions';
// v2 Component
import ConfluenceConnectionSettings from './admin/integrations/confluence/ConnectionSettings';
import ConfluenceContentSync from './admin/integrations/confluence/ConfluenceContentSync';
import CustomBrandingPage from './admin/settings/CustomBrandingPage';
import EspRoute from '../molecules/EspRoute';
import EspSwitch from '../molecules/EspSwitch';
import EspUserPropShape from '../../globals/EspUserPropShape';
import faqsIcon from '@iconify/icons-bx/bx-pencil';
import { firstAdminPathname } from '../../utils/checkPermissions';
import ImmutablePropTypes from 'react-immutable-proptypes';
import IntegrationCSVHistory from './admin/integrations/csv/IntegrationCSVHistory';
import IntegrationCSVMapping from './admin/integrations/csv/IntegrationCSVMapping';
import IntegrationCSVMappingAsset from './admin/integrations/csv/IntegrationCSVMappingAsset';
import IntegrationCSVMappingFields from './admin/integrations/csv/IntegrationCSVMappingFields';
import IntegrationCSVMappingHierarchyDepartment from './admin/integrations/csv/IntegrationCSVMappingHierarchyDepartment';
import IntegrationCSVMappingHierarchyLocation from './admin/integrations/csv/IntegrationCSVMappingHierarchyLocation';
import IntegrationCSVMappingPeople from './admin/integrations/csv/IntegrationCSVMappingPeople';
import IntegrationCSVMappingPreview from './admin/integrations/csv/IntegrationCSVMappingPreview';
import IntegrationCSVMappingUpload from './admin/integrations/csv/IntegrationCSVMappingUpload';
import IntegrationCSVScheduleList from './admin/integrations/csv/IntegrationCSVScheduleList';
import IntegrationCSVUpload from './admin/integrations/csv/IntegrationCSVUpload';
import IntegrationServicenowConnection from './admin/integrations/servicenow/IntegrationServicenowConnection';
import IntegrationServicenowUserSync from './admin/integrations/servicenow/IntegrationServicenowUserSync/IntegrationServicenowUserSync';
import IntegrationServicenowContentSync from './admin/integrations/servicenow/IntegrationServicenowContentSync';
import IntegrationServicenowIncidents from './admin/integrations/servicenow/IntegrationServicenowIncidents';
import IntegrationServicenowProducts from './admin/integrations/servicenow/IntegrationServicenowProducts';
import IntegrationServicenowUpdates from './admin/integrations/servicenow/IntegrationServicenowUpdates';
// import homeIcon from '@iconify/icons-bx/bx-home-alt';
import integrationsIcon from '@iconify/icons-bx/bx-cloud';
import interactionsIcon from '@iconify/icons-bx/bx-chat';
import kbIcon from '@iconify/icons-bx/bx-book';
import LinkMetadata from './admin/settings/LinkMetadata';
import locationsIcon from '@iconify/icons-bx/bx-world';
import { noop } from 'lodash';
import OffboardingPerformance from './admin/Activities/OffboardingPerformance';
import OffboardingSchedule from './admin/Activities/OffboardingSchedule';
import OffboardingTemplates from './admin/Activities/OffboardingTemplates';
import OktaAccount from './admin/integrations/okta/OktaAccount';
// Organisms
import OnAppWorkflowModal from '../organisms/OnAppWorkflowModal';
import OnboardingActivitiesPerformance from './admin/onboardingActivities/OnboardingActivitiesPerformance';
import OnboardingActivitiesSchedule from './admin/onboardingActivities/OnboardingActivitiesSchedule';
import OnboardingActivitiesTemplates from './admin/onboardingActivities/OnboardingActivitiesTemplates';
import onboardingIcon from '@iconify/icons-bx/bx-list-ol';
// import EmailIntegrationForm from '../molecules/EmailIntegrationForm';
import PrimaryEmailAccount from './admin/integrations/email/PrimaryEmailAccount';
import PropTypes from 'prop-types';
import referenceCatalogIcon from '@iconify/icons-bx/bx-cube';
import reportsIcon from '@iconify/icons-bx/bx-bar-chart';
import roleBundlesIcon from '@iconify/icons-bx/bx-box';
import serviceDepartmentIcon from '@iconify/icons-bx/bx-network-chart';
import serviceTeamIcon from '@iconify/icons-bx/bxs-user-badge';
import SettingsCustomization from './admin/settings/SettingsCustomization';
import SettingsEmailPolicy from './admin/settings/SettingsEmailPolicy';
import settingsIcon from '@iconify/icons-bx/bx-cog';
import SharepointConnectionSettings from './admin/integrations/sharepoint/ConnectionSettings';
import SharepointContentSync from './admin/integrations/sharepoint/SharepointContentSync';
import storefrontIcon from '@iconify/icons-bx/bx-cart';
import SupportMessageModal from '../molecules/SupportMessageModal';
import TeamsIntegrationConnectionSettings from './admin/integrations/teams/TeamsIntegrationConnectionSettings';
import TeamsIntegrationSdkConnection from './admin/integrations/teams/TeamsIntegrationSdkConnection';
import TeamsIntegrationHomeTab from './admin/integrations/teams/TeamsIntegrationHomeTab';
import TenantImage from '../molecules/TenantImage';
import TenantImageTypes from '../../utils/TenantImageTypes';
// Globals
import tenantMenu from '../../globals/tenantMenu';
// utils
import uiPathGenerator from '../../utils/uiPathGenerator';
import UserImg from '../../../v2/components/functional/UserImg';
import usersIcon from '@iconify/icons-bx/bx-user';
import taskIcon from '@iconify/icons-bx/bx-task';
import OktaSAML from './admin/integrations/okta/OktaSAML/OktaSAML';
import AzureSSOWithSAML from './admin/integrations/azure/SSOWithSAML/AzureSSOWithSAML';
import AzureBasicSSO from './admin/integrations/azure/BasicSSO/AzureBasicSSO';
const KnowledgeArticles_V2 = lazy(() =>
  import('./admin/KB_V2/KnowledgeArticles_V2')
);
const KBTable = lazy(() => import('./admin/KB_V2/KBTable/KBTable'));
const AdminUserGroups = lazy(() => import('./admin/UserGroups/UserGroups'));
const AddUser = lazy(() => import('./admin/users/AddUser'));
const AdminAuthentication = lazy(() => import('./admin/AdminAuthentication'));
const AdminBundleDetail = lazy(() => import('./admin/AdminBundleDetail'));
const AdminBundleList = lazy(() => import('./admin/AdminBundleList'));
const AdminBundleNew = lazy(() => import('./admin/AdminBundleNew'));
const AdminCatalogDetail = lazy(() => import('./admin/AdminCatalogDetail'));
const AdminCatalogList = lazy(() => import('./admin/AdminCatalogList'));
const AdminCatalogNew = lazy(() => import('./admin/AdminCatalogNew'));
const AdminDPCDetail = lazy(() => import('./admin/AdminDPCDetail'));
const AdminDPCList = lazy(() => import('./admin/AdminDPCList'));
const AdminFaqsList = lazy(() => import('./admin/AdminFaqsList'));
const AdminFaqsTopics = lazy(() => import('./admin/AdminFaqsTopics'));
const AdminFaqsV3 = lazy(() => import('./admin/AdminFaqsV3'));
const AdminFaqV2Detail = lazy(() => import('./admin/faqs/AdminFaqV2Detail'));
const AdminIntegrations = lazy(() => import('./admin/AdminIntegrations'));
const AdminLocations = lazy(() => import('./admin/AdminLocations'));
const AdminLocationsDetail = lazy(() => import('./admin/AdminLocationsDetail'));
const AdminOnboardingActivities = lazy(() =>
  import('./admin/AdminOnboardingActivities')
);
const OffboardingActivities = lazy(() =>
  import('./admin/Activities/OffboardingActivities')
);
const AdminReports = lazy(() => import('./admin/AdminReports'));
const AdminRoleBundleDetail = lazy(() =>
  import('./admin/AdminRoleBundleDetail')
);
const AdminRoleBundleList = lazy(() => import('./admin/AdminRoleBundleList'));
const AdminRoleBundleNew = lazy(() => import('./admin/AdminRoleBundleNew'));
const AdminServiceDepartmentDetail = lazy(() =>
  import('./admin/AdminServiceDepartmentDetail')
);
const AdminServiceDepartmentList = lazy(() =>
  import('./admin/AdminServiceDepartmentList')
);
const AdminServiceTeamsDetail = lazy(() =>
  import('./admin/AdminServiceTeamsDetail')
);
const AdminServiceTeamsImport = lazy(() =>
  import('./admin/AdminServiceTeamsImport')
);
const AdminServiceTeamsList = lazy(() =>
  import('./admin/AdminServiceTeamsList')
);
const AdminSettings = lazy(() => import('./admin/AdminSettings'));
const AdminUsers_v2 = lazy(() => import('./admin/AdminUsers_v2'));
const EditUser = lazy(() => import('./admin/users/EditUser'));
const ELC = lazy(() => import('./admin/settings/ELC/ELC'));
const TEST_MESSAGES = lazy(() => import('./admin/settings/Messages'));
const FaqNew = lazy(() => import('./admin/faqs/FaqNew'));
const IntegrationConfluence = lazy(() =>
  import('./admin/integrations/IntegrationConfluence')
);
const IntegrationCSV = lazy(() =>
  import('./admin/integrations/IntegrationCSV')
);
const IntegrationEmail = lazy(() =>
  import('./admin/integrations/IntegrationEmail')
);
const IntegrationOkta = lazy(() =>
  import('./admin/integrations/IntegrationOkta')
);
const IntegrationAzure = lazy(() =>
  import('./admin/integrations/IntegrationAzure')
);
const IntegrationServicenow = lazy(() =>
  import('./admin/integrations/IntegrationServicenow')
);
const IntegrationSharepoint = lazy(() =>
  import('./admin/integrations/IntegrationSharepoint')
);
const IntegrationTeams = lazy(() =>
  import('./admin/integrations/IntegrationTeams')
);
const Interactions = lazy(() =>
  import('./admin/InteractionsDashboard/display/InteractionsWrapper')
);
const InteractionsList = lazy(() =>
  import('./admin/interactions/InteractionsList')
);
const InteractionsTable = lazy(() =>
  import('./admin/InteractionsDashboard/display/InteractionsTable')
);
const InteractionsTableDetails = lazy(() =>
  import('./admin/InteractionsDashboard/display/InteractionsTableDetails')
);

const KnowledgeArticlesDetail = lazy(() =>
  import('./admin/knowledgeArticles/display/KnowledgeArticlesDetail')
);
const KnowledgeArticlesTable = lazy(() =>
  import('./admin/knowledgeArticles/display')
);
const LaunchInteraction = lazy(() =>
  import('./admin/interactions/LaunchInteraction')
);
const Notifications = lazy(() =>
  import('./admin/settings/Notifications/Notifications')
);

const TicketsDashboard = lazy(() => import('./admin/Tickets/Dashboard'));

const TicketsList = lazy(() => import('./admin/Tickets/List'));
const TicketDetail = lazy(() => import('./admin/Tickets/Detail/TicketDetail'));

const ReportBase = lazy(() => import('./admin/reports/ReportBase'));
const ReportBasicGrid = lazy(() => import('./admin/reports/ReportBasicGrid'));
const ReportGrid = lazy(() => import('./admin/reports/ReportGrid'));
const SurveyReport = lazy(() => import('./admin/reports/SurveyReport'));

const KnowledgeArticles = lazy(() =>
  import('./admin/knowledgeArticles/dashboard')
);

class AdminPage extends PureComponent {
  static propTypes = {
    configurationTenantMenu: ImmutablePropTypes.map.isRequired,
    currentUser: EspUserPropShape,
    doAdminGoodbyeActions: PropTypes.func,
    doAdminHelloActions: PropTypes.func,
    groupPermissions: PropTypes.objectOf(PropTypes.string).isRequired,
    isKBDashboardEnabled: PropTypes.bool,
    theme: PropTypes.shape({
      color: PropTypes.shape({
        primary: PropTypes.string,
        secondary: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    currentUser: null,
    doAdminGoodbyeActions: noop,
    doAdminHelloActions: noop,
    isKBDashboardEnabled: false,
  };

  componentDidMount() {
    const { doAdminHelloActions } = this.props;

    doAdminHelloActions();
  }

  componentWillUnmount() {
    const { doAdminGoodbyeActions } = this.props;
    doAdminGoodbyeActions();
  }

  render() {
    const {
      configurationTenantMenu,
      currentUser,
      groupPermissions,
      theme,
      isKBDashboardEnabled,
    } = this.props;
    const adminRoles = [
      groupPermissions.ADMIN,
      groupPermissions.BARISTA_ADMIN,
      groupPermissions.COMPLIANCE_ADMIN,
      groupPermissions.CUSTOMER_SERVICE_ADMIN,
      groupPermissions.FACILITIES_ADMIN,
      groupPermissions.FAQ_ADMIN,
      groupPermissions.FINANCE_ADMIN,
      groupPermissions.HR_ADMIN,
      groupPermissions.IT_ADMIN,
      groupPermissions.LEGAL_ADMIN,
      groupPermissions.MARKETING_ADMIN,
      groupPermissions.PAYROLL_ADMIN,
      groupPermissions.PROCUREMENT_ADMIN,
      groupPermissions.SALES_ADMIN,
      groupPermissions.SECURITY_ADMIN,
      groupPermissions.STUDENT_SERVICES_ADMIN,
      groupPermissions.TRAINING_ADMIN,
    ];

    // If you want to map the option to a toggle feature, add to esp-config/src/featureFlagOptions.js the property key, it should match to the one passed on web_menu
    // If it is not listened on featureFlagOptions by default the option would be visible
    const menuActivities = configurationTenantMenu?.get('activities')
      ? {
          icon: onboardingIcon,
          label: 'Workflows',
          links: [
            {
              content: 'Onboarding',
              permissions: [
                groupPermissions.ADMIN,
                groupPermissions.ONBOARD_SCHEDULER,
                groupPermissions.ACTIVITY_CREATOR,
              ],
              to: uiPathGenerator.genPath(
                'admin.adminActivities.onboardingActivities'
              ),
              web_menu: tenantMenu.onboardingActivities,
            },
            {
              content: 'Offboarding',
              permissions: [
                groupPermissions.ADMIN,
                groupPermissions.ONBOARD_SCHEDULER,
                groupPermissions.ACTIVITY_CREATOR,
              ],
              to: uiPathGenerator.genPath('admin.adminActivities.offboarding'),
              web_menu: tenantMenu.offboarding,
            },
          ],
          logo: onboardingIcon,
          permissions: [
            groupPermissions.ADMIN,
            groupPermissions.ONBOARD_SCHEDULER,
            groupPermissions.ACTIVITY_CREATOR,
          ],
          web_menu: tenantMenu.adminActivities,
        }
      : {
          content: 'Onboarding',
          icon: onboardingIcon,
          permissions: [
            groupPermissions.ADMIN,
            groupPermissions.ONBOARD_SCHEDULER,
            groupPermissions.ACTIVITY_CREATOR,
          ],
          to: uiPathGenerator.genPath(
            'admin.adminActivities.onboardingActivities'
          ),
          web_menu: tenantMenu.onboardingActivities,
        };

    // DEV-10358 menuUsers holds the app for Users List and User Groups It will replace the users section
    const menuUsers = {
      icon: usersIcon,
      label: 'Users',
      links: [
        {
          content: 'List',
          permissions: [groupPermissions.ADMIN],
          to: uiPathGenerator.genPath('admin.users_v2'),
        },
        {
          content: 'Groups',
          permissions: [groupPermissions.ADMIN],
          to: uiPathGenerator.genPath('admin.user_groups'),
        },
      ],
      permissions: [groupPermissions.ADMIN],
    };

    const menuTickets = {
      icon: taskIcon,
      label: 'Tickets',
      links: [
        {
          content: 'Reporting',
          permissions: adminRoles,
          to: uiPathGenerator.genPath('admin.tickets.dashboard'),
        },
        {
          content: 'List',
          permissions: adminRoles,
          to: uiPathGenerator.genPath('admin.tickets.list'),
        },
      ],
      permissions: adminRoles,
    };

    const adminMainMenu = [
      {
        content: 'Interactions',
        icon: interactionsIcon,
        permissions: adminRoles,
        to: uiPathGenerator.genPath('admin.interactionsDashboard'),
      },
      {
        content: 'FAQs',
        icon: faqsIcon,
        permissions: [groupPermissions.ADMIN, groupPermissions.FAQ_ADMIN],
        to: uiPathGenerator.genPath('admin.faqsV2'),
      },
      {
        content: 'Knowledge Articles',
        icon: kbIcon,
        permissions: adminRoles,
        to: isKBDashboardEnabled
          ? uiPathGenerator.genFullPattern('admin.knowledgeArticlesV2')
          : uiPathGenerator.genFullPattern('admin.knowledgeArticles'),
      },
      menuTickets,
      {
        content: 'Reference Catalog',
        icon: referenceCatalogIcon,
        permissions: [groupPermissions.ADMIN, groupPermissions.CATALOG_MANAGER],
        to: uiPathGenerator.genPath('admin.dpc'),
      },
      {
        content: 'Storefront Designer',
        icon: storefrontIcon,
        permissions: [groupPermissions.ADMIN, groupPermissions.CATALOG_MANAGER],
        to: uiPathGenerator.genPath('admin.storefrontDesigner'),
      },
      {
        content: 'Bundles',
        icon: bundlesIcon,
        permissions: [groupPermissions.ADMIN, groupPermissions.CATALOG_MANAGER],
        to: uiPathGenerator.genPath('admin.bundle'),
      },
      {
        content: 'Role Bundles',
        icon: roleBundlesIcon,
        permissions: [groupPermissions.ADMIN, groupPermissions.CATALOG_MANAGER],
        to: uiPathGenerator.genPath('admin.roleBundle'),
      },
      menuActivities,
      {
        content: 'Service Department',
        icon: serviceDepartmentIcon,
        permissions: [groupPermissions.ADMIN],
        to: uiPathGenerator.genPath('admin.serviceDepartment'),
        web_menu: tenantMenu.serviceDepartment,
      },

      {
        content: 'Service Teams',
        icon: serviceTeamIcon,
        permissions: [groupPermissions.ADMIN, groupPermissions.SERVICE_LEAD],
        to: uiPathGenerator.genPath('admin.serviceTeams'),
        web_menu: tenantMenu.serviceTeams,
      },
      menuUsers,
      {
        content: 'Integration Hub',
        icon: integrationsIcon,
        permissions: [groupPermissions.ADMIN],
        to: uiPathGenerator.genPath('admin.integrations'),
      },
      {
        content: 'Authentication',
        icon: authenticationIcon,
        permissions: [groupPermissions.ADMIN],
        to: uiPathGenerator.genPath('admin.authentication'),
      },
      {
        content: 'Reports',
        icon: reportsIcon,
        permissions: [
          groupPermissions.ADMIN,
          groupPermissions.HR_ADMIN,
          groupPermissions.IT_ADMIN,
          groupPermissions.FINANCE_ADMIN,
          groupPermissions.SECURITY_ADMIN,
          groupPermissions.FACILITIES_ADMIN,
          groupPermissions.MARKETING_ADMIN,
          groupPermissions.SALES_ADMIN,
          groupPermissions.CUSTOMER_SERVICE_ADMIN,
          groupPermissions.PAYROLL_ADMIN,
          groupPermissions.BARISTA_ADMIN,
        ],
        to: uiPathGenerator.genPath('admin.reports.list', {
          page: 1,
        }),
        web_menu: tenantMenu.reports,
      },
      {
        content: 'Locations',
        icon: locationsIcon,
        permissions: [groupPermissions.ADMIN],
        to: uiPathGenerator.genPath('admin.locations.list', {
          page: 1,
        }),
        web_menu: tenantMenu.locations,
      },
      // {
      //   content: 'Approval Rules',
      //   icon: approvalRulesIcon,
      //   permissions: [groupPermissions.ADMIN],
      //   to: uiPathGenerator.genPath('admin.approvalRules'),
      //   web_menu: tenantMenu.approvalRules,
      // },
      {
        content: 'Global Settings',
        icon: settingsIcon,
        permissions: [groupPermissions.ADMIN],
        to: uiPathGenerator.genPath('admin.globalSettings'),
      },
    ];

    const supportTrigger = (
      <Dropdown.Item icon='help circle' text='Feedback and Help' />
    );

    return currentUser ? (
      <AdminStructure
        header={
          <Menu borderless color='grey' style={{ height: '100%' }}>
            <Menu.Item fitted header>
              <Link to={uiPathGenerator.genFullPattern('app.toDo')}>
                <TenantImage
                  className='tenant'
                  size='mini'
                  type={TenantImageTypes.LOGO}
                />
              </Link>
            </Menu.Item>
            <AdminTitle />

            <Menu.Menu position='right'>
              <Dropdown
                item
                trigger={
                  <Image
                    style={{
                      maxWidth: '2.25em',
                    }}
                  >
                    <UserImg userId={currentUser.get('id')} />
                  </Image>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={NavLink}
                    icon='sign out'
                    text='Back to App'
                    to={uiPathGenerator.genPath('app.toDo')}
                  />
                  <SupportMessageModal trigger={supportTrigger} />
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          </Menu>
        }
        nav={
          <>
            <AdminStructure.Nav
              links={adminMainMenu
                .map((item) => {
                  if (!item) {
                    return null;
                  }
                  const mainItemIsAllowed = checkMenuPermissions(
                    item,
                    currentUser,
                    configurationTenantMenu
                  );
                  if (mainItemIsAllowed && item.links) {
                    const allowedSubLinks = item.links.map((i) => {
                      const isPermitted = checkMenuPermissions(
                        i,
                        currentUser,
                        configurationTenantMenu
                      );
                      const { content, to, icon } = i;

                      return (
                        isPermitted && {
                          icon,
                          label: content,
                          linkComponent: NavLink,
                          linkComponentProps: { to },
                        }
                      );
                    });

                    return {
                      ...item,
                      links: [...allowedSubLinks],
                    };
                  }

                  const { content, to, icon } = item;
                  return mainItemIsAllowed
                    ? {
                        icon,
                        label: content,
                        linkComponent: NavLink,
                        linkComponentProps: { to },
                      }
                    : null;
                })
                .filter((item) => item !== null)}
            />
            {/* <List
              divided
              link
              relaxed='very'
              size='large'
              style={{ borderRight: '1px solid #ccc', height: '100%' }}
              verticalAlign='middle'
            >
              {adminMainMenu.map((item) => {
                const {
                  activeClassName,
                  as,
                  className,
                  content,
                  href,
                  icon,
                  key,
                  onClick,
                  to,
                } = item;

                const showMenu = checkMenuPermissions(
                  item,
                  currentUser,
                  configurationTenantMenu
                );

                const props = Object.assign(
                  {
                    as,
                    className,
                    key,
                    onClick,
                  },
                  as === 'a' ? { href } : { activeClassName, to }
                );

                if (showMenu) {
                  return (
                    <List.Item {...props}>
                      {icon}
                      <List.Content>{content}</List.Content>
                    </List.Item>
                  );
                } else {
                  return null;
                }
              })}
            </List> */}
          </>
        }
        theme={theme}
      >
        <AdminStructure.Main>
          {/* OnAppWorkflowModal needs access to the React Router context */}
          <EspRoute component={OnAppWorkflowModal} />

          <EspSwitch>
            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin')}
              to={firstAdminPathname()}
            />
            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin.dpc')}
              to={uiPathGenerator.genPath('admin.dpc.list', {
                page: 1,
              })}
            />
            <EspRoute
              component={AdminDPCList}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.dpc.list')}
            />
            <EspRoute
              component={AdminDPCDetail}
              path={uiPathGenerator.genFullPattern('admin.dpc.detail')}
            >
              <EspSwitch>
                <EspRoute
                  component={AdminProductSpecs}
                  exact
                  isPermissionsProtected
                  path={uiPathGenerator.genFullPattern('admin.dpc.detail')}
                />

                <EspRoute
                  component={AdminProductFamily}
                  exact
                  isPermissionsProtected
                  path={uiPathGenerator.genFullPattern(
                    'admin.dpc.detail.family'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={
                isKBDashboardEnabled ? KnowledgeArticles_V2 : KnowledgeArticles
              }
              exact
              isPermissionsProtected
              path={
                isKBDashboardEnabled
                  ? uiPathGenerator.genFullPattern('admin.knowledgeArticlesV2')
                  : uiPathGenerator.genFullPattern('admin.knowledgeArticles')
              }
            />
            <EspRoute
              component={KBTable}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.knowledgeArticlesV2.table'
              )}
            />

            <EspRoute
              component={KnowledgeArticles_V2}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.knowledgeArticles')}
            />
            <EspRoute
              component={KnowledgeArticlesTable}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.knowledgeArticles.table'
              )}
            />
            <EspRoute
              component={KnowledgeArticlesDetail}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.knowledgeArticles.detail'
              )}
            />
            <EspRoute
              component={AdminUsers_v2}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.users_v2')}
            />

            <EspRoute
              component={AddUser}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.users_v2.addUser')}
            />
            <EspRoute
              component={EditUser}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.users_v2.editUser')}
            />
            <EspRoute
              component={AdminLocations}
              exact
              path={uiPathGenerator.genFullPattern('admin.locations.list')}
            />
            <EspRoute
              component={AdminLocationsDetail}
              exact
              path={uiPathGenerator.genFullPattern('admin.locations.view')}
            />
            <EspRoute
              component={AdminLocationsDetail}
              exact
              path={uiPathGenerator.genFullPattern('admin.locations.edit')}
            />
            <EspRoute
              component={AdminReports}
              exact
              path={uiPathGenerator.genFullPattern('admin.reports.list')}
            />
            <EspRoute
              component={ReportBase}
              exact
              path={uiPathGenerator.genFullPattern('admin.reports.view')}
            />
            <EspRoute
              component={ReportGrid}
              exact
              path={uiPathGenerator.genFullPattern('admin.reports.grid')}
            />
            <EspRoute
              component={ReportBasicGrid}
              exact
              path={uiPathGenerator.genFullPattern('admin.reports.custom')}
            />
            <EspRoute
              component={SurveyReport}
              exact
              path={uiPathGenerator.genFullPattern('admin.reports.survey')}
            />
            <EspRoute
              component={ReportBasicGrid}
              exact
              path={uiPathGenerator.genFullPattern(
                'admin.reports.custom.entity'
              )}
            />
            <EspRoute
              component={FaqNew}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.faqsV2.new')}
            />

            <EspRoute
              component={TicketsDashboard}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.tickets.dashboard')}
            />

            <EspRoute
              component={TicketsList}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.tickets.list')}
            />

            <EspRoute
              component={TicketDetail}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.tickets.edit')}
            />

            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin')}
              to={firstAdminPathname()}
            />

            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin.faqsV2.detail')}
              to={uiPathGenerator.genFullPattern(
                'admin.faqsV2.detail.responses'
              )}
            />
            <EspRoute
              component={AdminFaqV2Detail}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.faqsV2.detail')}
            >
              <EspSwitch>
                <EspRoute
                  component={AdminFaqV2Detail}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.faqsV2.detail.responses'
                  )}
                />

                <EspRoute
                  component={AdminFaqV2Detail}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.faqsV2.detail.matching'
                  )}
                />

                <EspRoute
                  component={AdminFaqV2Detail}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.faqsV2.detail.info'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin.faqsV2')}
              to={uiPathGenerator.genPath('admin.faqsV2.list', {
                page: 1,
              })}
            />
            <EspRoute
              component={AdminFaqsV3}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.faqsV2')}
            >
              <EspSwitch>
                <EspRoute
                  component={AdminFaqsList}
                  exact
                  path={uiPathGenerator.genFullPattern('admin.faqsV2.list', {
                    page: 1,
                  })}
                />

                <EspRoute
                  component={AdminFaqsTopics}
                  exact
                  path={uiPathGenerator.genFullPattern('admin.faqsV2.topics', {
                    page: 1,
                  })}
                />
              </EspSwitch>
            </EspRoute>
            {/*
                    DEV-11932:
                    1. Makes v3 the default.
                    2. Removes the config from the status page, so clients cannot disable it.

                    <EspRoute
                      component={AdminFaqsV2}
                      exact
                      isPermissionsProtected
                      path={uiPathGenerator.genFullPattern('admin.faqsV2.list')}
                    />
                  */}
            <EspRoute
              component={InteractionsList}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.adminInteractions')}
            />
            <EspRoute
              component={LaunchInteraction}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.adminInteractions.interactionChannel'
              )}
            />
            <EspRoute
              component={AdminIntegrations}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.integrations')}
            />
            <EspRoute
              component={IntegrationConfluence}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.integrations.confluence'
              )}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.integrations.confluence'
                  )}
                  to={uiPathGenerator.genPath('admin.integrations.confluence')}
                />

                <EspRoute
                  component={ConfluenceContentSync}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.confluence.contentSync'
                  )}
                />

                <EspRoute
                  component={ConfluenceConnectionSettings}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.confluence.connectionSettings'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={IntegrationAzure}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.integrations.azure')}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.integrations.azure'
                  )}
                  to={uiPathGenerator.genPath(
                    'admin.integrations.azure.ssoWithSAML'
                  )}
                />

                <EspRoute
                  component={AzureBasicSSO}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.azure.basicSSO'
                  )}
                />
                <EspRoute
                  component={AzureSSOWithSAML}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.azure.ssoWithSAML'
                  )}
                />
              </EspSwitch>
            </EspRoute>

            <EspRoute
              component={IntegrationEmail}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.integrations.email')}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.integrations.email'
                  )}
                  to={uiPathGenerator.genPath(
                    'admin.integrations.email.primary'
                  )}
                />

                <EspRoute
                  component={PrimaryEmailAccount}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.email.primary'
                  )}
                />

                <EspRoute
                  component={CaseInboxAccount}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.email.case'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={IntegrationCSV}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.integrations.csv')}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.integrations.csv'
                  )}
                  to={uiPathGenerator.genPath('admin.integrations.csv.history')}
                />

                <EspRoute
                  component={IntegrationCSVHistory}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.csv.history'
                  )}
                />

                <EspRoute
                  component={IntegrationCSVScheduleList}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.csv.schedule'
                  )}
                />

                <EspRoute
                  component={IntegrationCSVMapping}
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.csv.mapping'
                  )}
                >
                  <EspSwitch>
                    <EspRoute
                      component={IntegrationCSVMappingPeople}
                      path={uiPathGenerator.genFullPattern(
                        'admin.integrations.csv.mapping.people'
                      )}
                    >
                      <EspSwitch>
                        <EspRoute
                          component={IntegrationCSVMappingUpload}
                          exact
                          path={uiPathGenerator.genFullPattern(
                            'admin.integrations.csv.mapping.people'
                          )}
                        />

                        <EspRoute
                          component={IntegrationCSVMappingHierarchyDepartment}
                          exact
                          path={uiPathGenerator.genFullPattern(
                            'admin.integrations.csv.mapping.people.hierarchyDepartment'
                          )}
                        />

                        <EspRoute
                          component={IntegrationCSVMappingHierarchyLocation}
                          exact
                          path={uiPathGenerator.genFullPattern(
                            'admin.integrations.csv.mapping.people.hiearchyLocation'
                          )}
                        />

                        <EspRoute
                          component={IntegrationCSVMappingFields}
                          exact
                          path={uiPathGenerator.genFullPattern(
                            'admin.integrations.csv.mapping.people.mapFields'
                          )}
                        />

                        <EspRoute
                          component={IntegrationCSVMappingPreview}
                          exact
                          path={uiPathGenerator.genFullPattern(
                            'admin.integrations.csv.mapping.people.mapPreview'
                          )}
                        />
                      </EspSwitch>
                    </EspRoute>
                    <EspRoute
                      component={IntegrationCSVMappingAsset}
                      path={uiPathGenerator.genFullPattern(
                        'admin.integrations.csv.mapping.asset'
                      )}
                    >
                      <EspSwitch>
                        <EspRoute
                          component={IntegrationCSVMappingUpload}
                          exact
                          path={uiPathGenerator.genFullPattern(
                            'admin.integrations.csv.mapping.asset'
                          )}
                        />

                        <EspRoute
                          component={IntegrationCSVMappingFields}
                          exact
                          path={uiPathGenerator.genFullPattern(
                            'admin.integrations.csv.mapping.asset.mapFields'
                          )}
                        />
                        <EspRoute
                          component={IntegrationCSVMappingPreview}
                          exact
                          path={uiPathGenerator.genFullPattern(
                            'admin.integrations.csv.mapping.asset.mapPreview'
                          )}
                        />
                      </EspSwitch>
                    </EspRoute>
                  </EspSwitch>
                </EspRoute>

                <EspRoute
                  component={IntegrationCSVUpload}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.csv.upload'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={IntegrationServicenow}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.integrations.service_now'
              )}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.integrations.service_now'
                  )}
                  to={uiPathGenerator.genPath(
                    'admin.integrations.service_now.connection'
                  )}
                />

                <EspRoute
                  component={IntegrationServicenowConnection}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.service_now.connection'
                  )}
                />
                <EspRoute
                  component={IntegrationServicenowUserSync}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.service_now.userSync'
                  )}
                />

                <EspRoute
                  component={IntegrationServicenowContentSync}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.service_now.contentSync'
                  )}
                />

                <EspRoute
                  component={IntegrationServicenowProducts}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.service_now.products'
                  )}
                />

                <EspRoute
                  component={IntegrationServicenowIncidents}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.service_now.incidents'
                  )}
                />

                <EspRoute
                  component={IntegrationServicenowUpdates}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.service_now.updates'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={IntegrationOkta}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.integrations.okta')}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.integrations.okta'
                  )}
                  to={uiPathGenerator.genPath('admin.integrations.okta.sso')}
                />

                <EspRoute
                  component={OktaSAML}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.okta.sso'
                  )}
                />
                <EspRoute
                  component={OktaAccount}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.okta.account'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={IntegrationTeams}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.integrations.teams')}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.integrations.teams'
                  )}
                  to={uiPathGenerator.genPath(
                    'admin.integrations.teams.sdkConnection'
                  )}
                />

                <EspRoute
                  component={TeamsIntegrationConnectionSettings}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.teams.connectionSettings'
                  )}
                />

                <EspRoute
                  component={TeamsIntegrationSdkConnection}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.teams.sdkConnection'
                  )}
                />
                <EspRoute
                  component={TeamsIntegrationSdkSettings}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.teams.sdkSettings'
                  )}
                />

                <EspRoute
                  component={TeamsIntegrationHomeTab}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.teams.homeTab'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={Interactions}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.interactionsDashboard'
              )}
            />
            <EspRoute
              component={InteractionsTable}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.interactionsDashboard.drillDown'
              )}
            />
            <EspRoute
              component={InteractionsTableDetails}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.interactionsDashboard.drillDown.details'
              )}
            />
            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin.storefrontDesigner')}
              to={uiPathGenerator.genPath('admin.storefrontDesigner.list', {
                page: 1,
              })}
            />
            <EspRoute
              component={AdminCatalogList}
              exact
              path={uiPathGenerator.genFullPattern(
                'admin.storefrontDesigner.list'
              )}
            />
            <EspRoute
              component={AdminCatalogDetail}
              path={uiPathGenerator.genFullPattern(
                'admin.storefrontDesigner.detail'
              )}
            >
              <EspSwitch>
                <EspRoute
                  component={AdminProductSpecs}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.storefrontDesigner.detail'
                  )}
                />

                <EspRoute
                  component={AdminProductPermissionsContext}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.storefrontDesigner.detail.permissions'
                  )}
                />

                <EspRoute
                  component={AdminProductFulfillment}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.storefrontDesigner.detail.fulfillment'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={AdminCatalogNew}
              exact
              path={uiPathGenerator.genFullPattern(
                'admin.storefrontDesigner.new'
              )}
            />
            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin.bundle')}
              to={uiPathGenerator.genPath('admin.bundle.list', {
                page: 1,
              })}
            />
            <EspRoute
              component={AdminBundleList}
              exact
              path={uiPathGenerator.genFullPattern('admin.bundle.list')}
            />
            <EspRoute
              component={AdminBundleDetail}
              exact
              path={uiPathGenerator.genFullPattern('admin.bundle.detail')}
            />
            <EspRoute
              component={AdminBundleNew}
              exact
              path={uiPathGenerator.genFullPattern('admin.bundle.new')}
            />
            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin.roleBundle')}
              to={uiPathGenerator.genPath('admin.roleBundle.list', {
                page: 1,
              })}
            />
            <EspRoute
              component={AdminRoleBundleList}
              exact
              path={uiPathGenerator.genFullPattern('admin.roleBundle.list')}
            />
            <EspRoute
              component={AdminRoleBundleDetail}
              exact
              path={uiPathGenerator.genFullPattern('admin.roleBundle.detail')}
            />
            <EspRoute
              component={AdminRoleBundleNew}
              exact
              path={uiPathGenerator.genFullPattern('admin.roleBundle.new')}
            />
            <EspRoute
              component={AdminUserGroups}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.user_groups')}
            />
            <EspRoute
              component={OffboardingActivities}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.adminActivities.offboarding'
              )}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.adminActivities.offboarding'
                  )}
                  to={uiPathGenerator.genPath(
                    'admin.adminActivities.offboarding.templates'
                  )}
                />

                <EspRoute
                  component={OffboardingTemplates}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.adminActivities.offboarding.templates'
                  )}
                />

                <EspRoute
                  component={OffboardingSchedule}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.adminActivities.offboarding.schedule'
                  )}
                />

                <EspRoute
                  component={OffboardingPerformance}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.adminActivities.offboarding.performance'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={AdminOnboardingActivities}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.adminActivities.onboardingActivities'
              )}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.adminActivities.onboardingActivities'
                  )}
                  to={uiPathGenerator.genPath(
                    'admin.adminActivities.onboardingActivities.templates'
                  )}
                />

                <EspRoute
                  component={OnboardingActivitiesTemplates}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.adminActivities.onboardingActivities.templates'
                  )}
                />

                <EspRoute
                  component={OnboardingActivitiesSchedule}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.adminActivities.onboardingActivities.schedule'
                  )}
                />

                <EspRoute
                  component={OnboardingActivitiesPerformance}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.adminActivities.onboardingActivities.performance'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin.serviceDepartment')}
              to={uiPathGenerator.genPath('admin.serviceDepartment.list')}
            />
            <EspRoute
              component={AdminServiceDepartmentList}
              exact
              path={uiPathGenerator.genFullPattern(
                'admin.serviceDepartment.list'
              )}
            />
            <EspRoute
              component={AdminServiceDepartmentDetail}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.serviceDepartment.detail'
              )}
            >
              <EspSwitch>
                <EspRoute
                  component={AdminServiceDetailTeams}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.serviceDepartment.detail.teams'
                  )}
                />

                <EspRoute
                  component={AdminServiceDetailCategories}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.serviceDepartment.detail.categories'
                  )}
                />
                <EspRoute
                  component={AdminServiceDepartmentDetailCaseManagement}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.serviceDepartment.detail.caseManagement'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <Redirect
              exact
              from={uiPathGenerator.genFullPattern('admin.serviceTeams')}
              to={uiPathGenerator.genPath('admin.serviceTeams.list')}
            />
            <EspRoute
              component={AdminServiceTeamsList}
              exact
              path={uiPathGenerator.genFullPattern('admin.serviceTeams.list')}
            />
            <EspRoute
              component={AdminServiceTeamsDetail}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.serviceTeams.detail')}
            >
              <EspSwitch>
                <EspRoute
                  component={AdminServiceTeamMembers}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.serviceTeams.detail.members'
                  )}
                />
                <EspRoute
                  component={AdminServiceTeamSetting}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.serviceTeams.detail.settings'
                  )}
                />
                <EspRoute
                  component={AdminServiceTeamLocation}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.serviceTeams.detail.location'
                  )}
                />
                <EspRoute
                  component={AdminServiceTeamSubstates}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.serviceTeams.detail.substates',
                    { page: 1 }
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={AdminServiceTeamsImport}
              exact
              path={uiPathGenerator.genFullPattern('admin.serviceTeams.import')}
            />
            <EspRoute
              component={AdminSettings}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.globalSettings')}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern('admin.globalSettings')}
                  to={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.branding'
                  )}
                />

                <EspRoute
                  component={CustomBrandingPage}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.branding'
                  )}
                />

                <EspRoute
                  component={SettingsEmailPolicy}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.emailPolicy'
                  )}
                />

                <EspRoute
                  component={SettingsCustomization}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.menu'
                  )}
                />

                <EspRoute
                  component={LinkMetadata}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.linkMetadata'
                  )}
                />

                <EspRoute
                  component={Notifications}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.notifications'
                  )}
                />

                <EspRoute
                  component={AdminFiles}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.files'
                  )}
                />

                <EspRoute
                  component={AdminHelp}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.help'
                  )}
                />

                <EspRoute
                  component={ELC}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.elc'
                  )}
                />
                <EspRoute
                  component={TEST_MESSAGES}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.globalSettings.messages'
                  )}
                />
              </EspSwitch>
            </EspRoute>
            <EspRoute
              component={AdminAuthentication}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.authentication')}
            />
            {/* <EspRoute
              component={AdminApprovalRules}
              exact
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern('admin.approvalRules')}
            /> */}
            <EspRoute
              component={IntegrationSharepoint}
              isPermissionsProtected
              path={uiPathGenerator.genFullPattern(
                'admin.integrations.sharepoint'
              )}
            >
              <EspSwitch>
                <Redirect
                  exact
                  from={uiPathGenerator.genFullPattern(
                    'admin.integrations.sharepoint'
                  )}
                  to={uiPathGenerator.genPath('admin.integrations.sharepoint')}
                />

                <EspRoute
                  component={SharepointContentSync}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.sharepoint.contentSync'
                  )}
                />

                <EspRoute
                  component={SharepointConnectionSettings}
                  exact
                  path={uiPathGenerator.genFullPattern(
                    'admin.integrations.sharepoint.connectionSettings'
                  )}
                />
              </EspSwitch>
            </EspRoute>
          </EspSwitch>
        </AdminStructure.Main>
      </AdminStructure>
    ) : null;
  }
}

// eslint-disable-next-line no-class-assign -- DEV-1526
AdminPage = AdminPageController(AdminPage);

export default AdminPage;
