import React, { Fragment } from 'react';
import { unified } from 'unified';
import rehypeReact from 'rehype-react';
import remarkRehype from 'remark-rehype';
import rehypeHighlight from 'rehype-highlight';
import remarkParse from 'remark-parse';
import remarkGfm from 'remark-gfm';
// import remarkStringify from 'remark-stringify';
import EspMarkdownDefaultProps from './EspMarkdownDefaultProps';
import getNormalizedTextToMarkdown from './utils/getNormalizedTextToMarkdown';
import allowList from './plugins/allowList';
import customComponents from './customComponents';
import { reduce } from 'lodash';

/**
 * @typedef {Object} EspMarkdownProps
 * @property {boolean} [avoidFollowLinks] - Whether to avoid following links.
 * @property {Record<string, React.ReactElement | ((props) => React.ReactElement)>} [customComponents] - Custom components to be used in the markdown rendering.
 * @property {Record<string, any>} [globalVariables] - Global variables to be used in the markdown rendering.
 * @property {boolean} [globalsAsUpperCase] - Whether to convert global variables to uppercase.
 * @property {boolean} [isBarista] - Whether the markdown is being rendered in Barista.
 * @property {boolean} [isScoped] - Whether the markdown is being rendered in a scoped environment.
 * @property {(record: Record<string, string>) => void} [loadKbArticle] - Function to load a KB article.
 * @property {boolean} [noAnchorLinks] - Whether to remove anchor links.
 * @property {boolean} [noAnchorTag] - Whether to remove anchor tags.
 * @property {boolean} [noExternalLinks] - Whether to remove external links.
 * @property {boolean} [noInternalLinks] - Whether to remove internal links.
 * @property {boolean} [noMarkdownEmphasis] - Whether to remove markdown emphasis.
 * @property {boolean} [noMarkdownLinks] - Whether to remove markdown links.
 * @property {Function} [openInternalLinkFromBarista] - Function to open an internal link from Barista.
 * @property {Record<string, { entity?: keyof typeof import('esp-globals').EspEntities; label: string; pk?: number }>} [paramValues] - Parameter values to be used in the markdown rendering.
 * @property {boolean} [removeTenantHost] - Whether to remove the tenant host.
 * @property {string[]} [supportedNodes] - Supported nodes for the markdown rendering.
 * @property {string} text - The markdown text to be rendered.
 */
/**
 * @param {EspMarkdownProps} props - The props for the EspMarkdown component.
 * @returns {React.ReactElement<unknown, string | React.JSXElementConstructor<any>>}>} - The rendered EspMarkdown component.
 */
const EspMarkdown = (props) => {
  // @type {React.ReactElement<unknown, string | React.JSXElementConstructor<any>>}>}
  const content = unified()
    .use(allowList, props)
    // @ts-ignore
    .use(remarkParse)
    // @ts-ignore
    .use(remarkGfm)
    // @ts-ignore
    .use(remarkRehype)
    // @ts-ignore
    // .use(remarkStringify)
    // @ts-ignore
    .use(rehypeHighlight)
    // @ts-ignore
    .use(rehypeReact, {
      Fragment,
      components: reduce(
        { ...customComponents, ...props.customComponents },
        (acc, value, key) => {
          return Object.assign(acc, {
            [key]: (remarkReactProps) =>
              // @ts-ignore
              React.createElement(value, {
                ...remarkReactProps,
                extendedProps: props,
              }),
          });
        },
        {}
      ),
      createElement: React.createElement,
    })
    .processSync(
      getNormalizedTextToMarkdown({
        props: props,
        text: props.text,
      })
    ).result;

  return content;
};

EspMarkdown.defaultProps = EspMarkdownDefaultProps;

export default EspMarkdown;
