import KbLink from './components/KbLink';

/**
 * @typedef {Record<string, React.ReactElement | ((props) => React.ReactElement)>} CustomComponents - Custom components to be used in the markdown rendering.
 */

/**
 * @type {CustomComponents}
 */
const customComponents = {
  a: KbLink,
};

export default customComponents;
