import { Label, List, Menu, Modal } from 'semantic-ui-react';
import React, { useCallback, useState } from 'react';

// Molecules
import BaristaTrigger from './BaristaTrigger';
// Organisms
import ChangePasswordForm from '../organisms/ChangePasswordForm';
import ChatBarista from './ChatBarista';
// Utils
import checkMenuPermissions from '../../utils/checkMenuPermissions';
import EspHero from './EspHero';
import IconifyNavIcon from './IconifyNavIcon';
// Atoms
// Controllers
import EspNavController from '../controllers/EspNavController';
// Globals
import EspUserPropShape from '../../globals/EspUserPropShape';
import ImmutablePropTypes from 'react-immutable-proptypes';
// Packages
import { intl } from 'esp-util-intl';
import { Link, NavLink } from 'react-router-dom';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import ScrollArea from '../atoms/ScrollArea';
import tenantMenu from '../../globals/tenantMenu';
import uiPathGenerator from '../../utils/uiPathGenerator';
import { UserAgentUtils } from 'esp-globals';
import WindowDimensionProvider from './WindowDimensionProvider';
import EspIcon from '../atoms/EspIcon';
import Immutable from 'immutable';

const propTypes = {
  chatbot_name: PropTypes.string,
  closeMoreMenu: PropTypes.func,
  closeResetPasswordModal: PropTypes.func,
  configurationTenantMenu: ImmutablePropTypes.map.isRequired,
  currentUser: EspUserPropShape.isRequired,
  getHelpLabelFromBackend: PropTypes.string,
  groupPermissions: PropTypes.objectOf(PropTypes.string).isRequired,
  isBaristaOpened: PropTypes.bool,
  isChatV2Enabled: PropTypes.bool,
  loadApprovals: PropTypes.func,
  promptResetPassword: PropTypes.bool,
  shopLabelFromBackend: PropTypes.string,
  simulateMobile: PropTypes.bool,
  toggleMoreMenu: PropTypes.func,
  unreadApprovalCount: PropTypes.number,
  unreadCount: PropTypes.number,
};

const EspNav = ({
  chatbot_name,
  closeMoreMenu = noop,
  currentUser = Immutable.Map(),
  configurationTenantMenu = Immutable.Map(),
  groupPermissions = [],
  closeResetPasswordModal = noop,
  getHelpLabelFromBackend = null,
  isBaristaOpened = false,
  isChatV2Enabled = false,
  loadApprovals = noop,
  promptResetPassword = false,
  shopLabelFromBackend = null,
  toggleMoreMenu = noop,
  simulateMobile = false,
  unreadApprovalCount = 0,
  unreadCount = 0,
}) => {
  const [isMoreNavExpanded, setIsMoreNavExpanded] = useState(false);
  const [isApprovalsLoaded, setIsApprovalsLoaded] = useState(false);

  const handleMoreMenuListClick = useCallback(
    (e) => {
      const { target, currentTarget } = e;

      // click on menu item bubbles up to the <List>,
      // currentTarget is always the DOM element for the <List>,
      // if target isn't the same DOM element, user clicked on something inside it (an item)
      // and we should close the menu
      const isClickOnItem = target !== currentTarget;

      if (isClickOnItem) {
        closeMoreMenu();
      }
    },
    [closeMoreMenu]
  );

  const handleCloseMoreAndRegister = useCallback(() => {
    setIsMoreNavExpanded(false, () => {
      closeMoreMenu();
    });
  }, [closeMoreMenu]);

  const shouldDisplayShop = Boolean(configurationTenantMenu.get('store'));

  const shouldHideContacts = Boolean(
    configurationTenantMenu.get('disableDirectory_hideContacts')
  );

  const shouldWeDisplayApprovalsOnMainMenu = Boolean(
    configurationTenantMenu.get('approvalsMainMenu')
  );

  const isMobile =
    UserAgentUtils.isIOS() ||
    UserAgentUtils.isAndroidWeb() ||
    UserAgentUtils.isCordova() ||
    simulateMobile;

  const shouldDisplayCasesOnMainMenu = Boolean(
    configurationTenantMenu.get('casesMainMenu')
  );

  // if the unread count is large, show a 9+ instead
  let unreadDisplay = unreadCount;
  if (unreadCount > 9) {
    unreadDisplay = '9+';
  }

  if (shouldWeDisplayApprovalsOnMainMenu && !isApprovalsLoaded) {
    setIsApprovalsLoaded(true);
    loadApprovals();
  }

  // If you want to map the option to a toggle feature, add to esp-config/src/featureFlagOptions.js the property key, it should match to the one passed on web_menu
  // If it is not listened on featureFlagOptions by default the option would be visible

  const secondMainMenuItem = () => {
    if (!shouldHideContacts) {
      return {
        as: NavLink,
        className: 'nav-directory',
        content: intl.formatMessage({
          id: 'label.navigation_contacts',
        }),
        icon: 'contacts',
        key: 1,
        onClick: () => handleCloseMoreAndRegister('contacts'),
        to: uiPathGenerator.genPath('app.directory'),
      };
    }

    if (shouldDisplayCasesOnMainMenu && isMobile) {
      return {
        as: NavLink,
        className: 'cases-icon nav-directory',
        content: intl.formatMessage({
          id: 'label.navigation_cases',
        }),
        icon: 'fa-solid:tasks',
        id: 'cases_main_menu_mobile',
        key: 1,
        noPermissions: [groupPermissions.ADMIN],
        onClick: () => handleCloseMoreAndRegister('cases'),
        permissions: [
          groupPermissions.SERVICE_LEAD,
          groupPermissions.SERVICE_AGENT,
        ],
        to: uiPathGenerator.genPath('app.casesFeed'),
        useIconifyIcon: true,
        web_menu: tenantMenu.caseMgmt,
      };
    }

    if (shouldDisplayShop) {
      return {
        as: NavLink,
        className: 'nav-directory',
        content:
          shopLabelFromBackend ||
          intl.formatMessage({
            id: 'label.navigation_shop',
          }),
        icon: 'shopping cart',
        key: 1,
        to: uiPathGenerator.genPath('app.catalog'),
      };
    }

    return null;
  };

  const mainNav = [
    {
      as: NavLink,
      className: 'nav-to-do',
      content: intl.formatMessage({
        id: 'label.navigation_home',
      }),
      icon: 'home',
      key: 0,
      onClick: () => handleCloseMoreAndRegister('home'),
      to: uiPathGenerator.genPath('app.toDo'),
    },
    secondMainMenuItem(),
    {
      as: NavLink,
      className: 'nav-socialize',
      content: intl.formatMessage({
        id: 'label.navigation_messages',
      }),
      icon: 'message',
      key: 3,
      onClick: () => handleCloseMoreAndRegister('messages'),
      to: uiPathGenerator.genPath('app.messages.navigation.scoped'),
      unreadCount: unreadDisplay,
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_approvals',
      }),
      forceToHide: !shouldWeDisplayApprovalsOnMainMenu,
      icon: 'thumbs up outline',
      id: 'approvals_main_menu',
      key: 'approvals',
      onClick: () => handleCloseMoreAndRegister('approvals'),
      to: uiPathGenerator.genPath('app.approvalsFeed'),
      unreadCount: unreadApprovalCount,
      web_menu: tenantMenu.approvals,
    },
    {
      as: NavLink,
      className: 'cases-icon',
      content: intl.formatMessage({
        id: 'label.navigation_cases',
      }),
      forceToHide: !shouldDisplayCasesOnMainMenu || isMobile,
      icon: 'fa-solid:tasks',
      id: 'cases_main_menu',
      key: 'cases',
      noPermissions: [groupPermissions.ADMIN],
      onClick: () => handleCloseMoreAndRegister('cases'),
      permissions: [
        groupPermissions.SERVICE_LEAD,
        groupPermissions.SERVICE_AGENT,
      ],
      to: uiPathGenerator.genPath('app.casesFeed'),
      useIconifyIcon: true,
      web_menu: tenantMenu.caseMgmt,
    },
    {
      className: 'nav-more',
      content: intl.formatMessage({
        id: `label.navigation_${isMoreNavExpanded ? 'less' : 'more'}`,
      }),
      icon: 'more',
      id: 'menu-open',
      key: 4,
      onClick: () => {
        toggleMoreMenu();
        setIsMoreNavExpanded((isMoreNavExpanded) => !isMoreNavExpanded);
      },
    },
  ];

  const moreNav = [
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_cases',
      }),
      forceToHide:
        (shouldDisplayCasesOnMainMenu && isMobile && shouldHideContacts) ||
        (shouldDisplayCasesOnMainMenu && !isMobile),
      id: 'cases_more_nav',
      key: 'cases',
      noPermissions: [groupPermissions.ADMIN],
      onClick: () => handleCloseMoreAndRegister('cases'),
      permissions: [
        groupPermissions.SERVICE_LEAD,
        groupPermissions.SERVICE_AGENT,
      ],
      to: uiPathGenerator.genPath('app.casesFeed'),
      web_menu: tenantMenu.caseMgmt,
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_my_profile',
      }),
      id: 'edit_profile_btn',
      key: 'my-profile',
      onClick: () => handleCloseMoreAndRegister('my profile'),
      to: uiPathGenerator.genPath('app.directory.detail', {
        userID: currentUser.get('id'),
      }),
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_approvals',
      }),
      forceToHide: shouldWeDisplayApprovalsOnMainMenu,
      id: 'approvals_more_nav',
      key: 'approvals',
      onClick: () => handleCloseMoreAndRegister('approvals'),
      to: uiPathGenerator.genPath('app.approvalsFeed'),
      web_menu: tenantMenu.approvals,
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_requests',
      }),
      key: 'requests',
      onClick: () => handleCloseMoreAndRegister('my requests'),
      to: uiPathGenerator.genPath('app.requests'),
      web_menu: tenantMenu.my_requests,
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_my_equipment',
      }),
      key: 'equipment',
      onClick: () => handleCloseMoreAndRegister('my equipment'),
      to: uiPathGenerator.genPath('app.equipment'),
      web_menu: tenantMenu.my_equipment,
    },
    {
      // Normal Shop secondary menu
      as: NavLink,
      content:
        shopLabelFromBackend ||
        intl.formatMessage({ id: 'label.navigation_shop' }),
      forceToHide: !shouldDisplayShop,
      key: 'catalog',
      onClick: () => handleCloseMoreAndRegister('shop'),
      to: uiPathGenerator.genPath('app.catalog'),
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_announcements',
      }),
      key: 'announcements',
      onClick: () => handleCloseMoreAndRegister('announcements'),
      permissions: [groupPermissions.ADMIN, groupPermissions.ANNOUNCER],
      to: uiPathGenerator.genPath('app.announcements'),
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_new_hire_progress',
      }),
      forceToHide:
        currentUser.get('is_admin') && !currentUser.get('is_manager'),
      key: 'new-hire-progress',
      onClick: () => handleCloseMoreAndRegister('new hire progress'),
      permissions: [groupPermissions.ADMIN, groupPermissions.MANAGER],
      to: uiPathGenerator.genPath('app.newHireProgress'),
      web_menu: tenantMenu.onboardingActivitiesManager,
    },
    {
      as: NavLink,
      className: 'nav-admin',
      content: intl.formatMessage({
        id: 'label.navigation_admin',
      }),
      forceToHide:
        UserAgentUtils.isIOS() ||
        UserAgentUtils.isAndroidWeb() ||
        UserAgentUtils.isCordova(), // Display the Admin menu only if it's not run on Android and iOS browser
      id: 'nav-admin',
      key: 'admin',
      onClick: () => handleCloseMoreAndRegister('admin'),
      permissions: [
        groupPermissions.ADMIN,
        groupPermissions.CATALOG_MANAGER,
        groupPermissions.ACTIVITY_CREATOR,
        groupPermissions.ONBOARD_SCHEDULER,
        groupPermissions.FAQ_ADMIN,
        groupPermissions.FAQ_EDITOR,
        groupPermissions.SERVICE_LEAD,
        groupPermissions.HR_ADMIN,
        groupPermissions.IT_ADMIN,
        groupPermissions.CUSTOMER_SERVICE_ADMIN,
        groupPermissions.FINANCE_ADMIN,
        groupPermissions.SECURITY_ADMIN,
        groupPermissions.FACILITIES_ADMIN,
        groupPermissions.MARKETING_ADMIN,
        groupPermissions.SALES_ADMIN,
        groupPermissions.PAYROLL_ADMIN,
        groupPermissions.BARISTA_ADMIN,
      ],
      to: uiPathGenerator.genPath('admin'),
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_logout',
      }),
      id: 'logout-button',
      key: 'logout',
      noPermissions: [groupPermissions.KIOSK], // noPermissions Check GROUP that are not allow to see this Menu
      onClick: () => handleCloseMoreAndRegister('logout'),
      to: uiPathGenerator.genPath('logout'),
    },
    {
      as: NavLink,
      content: intl.formatMessage({
        id: 'label.navigation_about_barista',
      }),
      id: 'about-barista',
      key: 'about',
      noPermissions: [groupPermissions.KIOSK], // noPermissions Check GROUP that are not allow to see this Menu
      onClick: () => handleCloseMoreAndRegister('aboutBarista'),
      to: uiPathGenerator.genPath('app.aboutBarista'),
    },
  ];

  if (isChatV2Enabled) {
    const chatbotname = chatbot_name || 'Barista';
    const chatV2Label = intl.formatMessage({
      id: 'label.try_the_new_chatbotname_experience',
      values: {
        chatbotname,
      },
    });
    const chatV2 = {
      as: Link,
      className: 'new_chat-icon',
      content: chatV2Label,
      icon: 'ic:outline-new-releases',
      id: 'chatv2',
      key: 'chatv2',
      onClick: () => handleCloseMoreAndRegister('chatV2'),
      target: '_blank',
      to: uiPathGenerator.genPath('chatV2App.chat'),
      useIconifyIcon: true,
    };
    mainNav.push(chatV2);
  }

  const baristaTrigger = (
    <BaristaTrigger
      onCloseMoreMenu={handleCloseMoreAndRegister}
      text={
        getHelpLabelFromBackend ||
        intl.formatMessage({ id: 'label.navigation_barista_get_help' })
      }
    />
  );

  const modalCloseButton = (
    <Menu.Item className='close'>
      {intl.formatMessage({
        id: 'label.exit',
      })}
    </Menu.Item>
  );
  return (
    <div id='esp-nav'>
      {/* <Button
          activeClassName='active'
          as={NavLink}
          basic
          circular
          icon='setting'
          id='user-settings'
          inverted
          to={uiPathGenerator.genPath('app.settings')}
        />*/}
      <EspHero small user={currentUser} />
      {/* <p className="lead">What would you like to get done today?</p>*/}

      <ScrollArea use3D={false}>
        <List
          as='nav'
          className='main-nav'
          divided
          relaxed='very'
          verticalAlign='middle'
        >
          <WindowDimensionProvider>
            <ChatBarista trigger={baristaTrigger} />
          </WindowDimensionProvider>
          <Modal
            className='scrolling'
            closeIcon={modalCloseButton}
            closeOnDimmerClick={false}
            onClose={closeResetPasswordModal}
            open={promptResetPassword}
            size='small'
          >
            <ChangePasswordForm />
          </Modal>

          {mainNav.map((item) => {
            if (!item || item?.forceToHide) {
              return null;
            }

            const {
              as,
              className,
              content,
              icon,
              id,
              key,
              onClick,
              to,
              useIconifyIcon,
              unreadCount,
              target,
            } = item;

            return (
              <List.Item
                as={as || void 0}
                className={className}
                data-testid={id}
                id={id}
                key={key}
                onClick={onClick}
                target={target || void 0}
                to={to || void 0}
              >
                {useIconifyIcon && <IconifyNavIcon icon={icon} />}
                {!useIconifyIcon && <EspIcon name={icon} />}
                <List.Content>{content}</List.Content>
                {unreadCount &&
                (Number(unreadCount) > 0 || isNaN(Number(unreadCount))) ? (
                  <Label
                    className='primary'
                    content={unreadCount}
                    floating
                    key='3'
                  />
                ) : null}
              </List.Item>
            );
          })}
        </List>
        <List
          className='more-nav'
          divided
          onClick={handleMoreMenuListClick}
          relaxed='very'
          style={
            isBaristaOpened
              ? {
                  display: 'none',
                }
              : void 0
          }
          verticalAlign='middle'
        >
          {moreNav.map((item) => {
            if (!item) {
              return null;
            }

            const {
              activeClassName,
              as,
              className,
              content,
              forceToHide,
              id,
              key,
              onClick,
              to,
              unreadCount,
            } = item;
            const showMenu = checkMenuPermissions(
              item,
              currentUser,
              configurationTenantMenu
            );

            if (showMenu && !forceToHide) {
              return (
                <List.Item
                  activeClassName={activeClassName}
                  as={as}
                  className={className}
                  data-testid={id}
                  id={id}
                  key={key}
                  onClick={onClick}
                  to={to}
                >
                  <List.Content>{content}</List.Content>
                  {unreadCount > 0 && (
                    <Label color='red' floating key='3'>
                      {unreadCount}
                    </Label>
                  )}
                </List.Item>
              );
            } else {
              return null;
            }
          })}
        </List>
      </ScrollArea>
    </div>
  );
};

EspNav.propTypes = propTypes;

const EspNavTest = EspNav;

export { EspNavTest };
export default EspNavController(EspNav);
