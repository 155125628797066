// Replace utils
import replaceNewLine from './replaceNewLine';
import replaceBold from './replaceBold';
import replaceDateWithLocalDateFormat from './replaceDateWithLocalDateFormat';
import replaceGlobalVariables from './replaceGlobalVariables';
import replaceExternalLink from './replaceExternalLink';
import replaceInternalLink from './replaceInternalLink';
import replaceParamVariables from './replaceParamVariables';
import replaceDiscWithDash from './replaceDiscWithDash';
import replaceLinkWithTarget from './replaceLinkWithTarget';

/**
 * Normalizes the text into valid markdown
 * @param {Object} config - object which has the text and the props of EspMarkdown
 * @param {string} config.text - the text to be normalized
 * @param {import ('../EspMarkdown').EspMarkdownProps} config.props - EspMarkdown props
 * @returns {string} text with valid markdown
 */
const getNormalizedTextToMarkdown = ({ text, props }) => {
  return [
    replaceBold,
    replaceDateWithLocalDateFormat,
    replaceGlobalVariables,
    replaceExternalLink,
    replaceInternalLink,
    replaceParamVariables,
    replaceDiscWithDash,
    replaceNewLine,
    replaceLinkWithTarget,
  ].reduce((acc, fn) => (acc = fn(acc, props)), text);
};

export default getNormalizedTextToMarkdown;
