import { useQuery } from '@tanstack/react-query';

import _APIcall from '../../v1/utils/APIcall';
import endpointGenerator from '../../v1/utils/endpointGenerator';

const useTenantHook = () => {
  const twoMinutes = 120000;

  const tenantQueryName = 'tenantQuery';
  const tenantQuery = useQuery(
    [tenantQueryName],
    async () => {
      const result = await _APIcall.get({
        token: true,
        url: endpointGenerator.genPath('espTenant.tenants'),
      });

      return result;
    },
    {
      staleTime: twoMinutes,
    }
  );

  return {
    isTenantLoading: tenantQuery?.isLoading,
    tenantError: tenantQuery?.error,
    tenantInfo: tenantQuery?.data?.body?.results[0] || {},
  };
};

export default useTenantHook;
